import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { OBTAIN_TOKEN } from "../actionTypes";
import { captureAuthToken } from "../api";
import { ObtainToken } from "../actions";

const listAuthToken = (action$) =>
  action$.pipe(
    ofType(OBTAIN_TOKEN),
    toParams,
    makeRequest(captureAuthToken),
    map(ObtainToken)
  );
export default combineEpics(listAuthToken);
