export const BUILD_CODE =
  "RkU6Q2ReyGdaGl9yOmNXmS4yRhszNfphW96q5dtfg1mtxDoWlAUy8Q==";
export const PUT_BUILD_CODE =
  "tCEN4g/EgcDP0GqeDiQ20UQwxDx0CbZWUo2Vnbkfj7a0ePWJ/E7AGQ==";
export const POST_BUILD_CODE =
  "ZDXYCD4fvVap7gfAa/Ef9k6aO7DRXp4Cg6sk39IiEsCX2hpiMy2mVg==";
export const ADVISER_CODE =
  "7lqMyS9TDjMgX9m5cRZgJO9aEkMpkoBZr5I0CRjICqMVmaoiAopiGQ==";
export const ATC_CODE =
  "DPegxpNGkWyQedi3IaPCk/zsigYLW4A8HO9YOCqDLVLtDxUzan6MKg==";
export const PUT_ADVISER_CODE =
  "X0v2Hj63tu5voGf7PR9kGg27lSWbdqoiObvs1/gJDgyv1ngGOEVpNQ==";
export const POST_ADVISER_CODE =
  "5cyv7eYuLv4TWLt3hLNDPXCC1q91eq3FKTmNz4NDcatLci304xv07A==";
export const PUT_ATC_CODE =
  "tM6Gwd6me3AcPRJqb/VezC9GUesMVQI0D10i3FWe90MLxRkacTaGxw==";
export const POST_ATC_CODE =
  "eMDaEM5jEop2vb7rLkx1Tk/dGmhJLugUNTz71yXHbnBgaBYrYUywSQ==";
export const POST_NOTIFICATIONS_CODE =
  "zJwHz5qPIU1/TBxpujGyu1WPgb9t0sP3b9yV4k1kruoy4RClEzP5DQ==";
export const FIND_NOTIFICATIONS_LOG_CODE =
  "D9U8my90kIW/djrpxSBISym6naAp2PlcvjRk7DRVfzNrYLRCa3nIRw==";
export const PUT_NOTIFICATIONS_CODE =
  "a6puuEJ5cLbunc7pfa3s93qkKmVdW8clOy8qxhBv3yaoIqPnvtavIA==";
export const FIND_ADVISER_CODE =
  "1LNcxeF61vp6gaAAcwlKNjKDkZnktvfmzooLpagZtauJWKQTSMmycw==";
export const FIND_ATC_CODE =
  "wPvuNrkVhZLYenDXjvY0n9Ijuy52PtK3KRxLSVOdTRAhZINLwhvgrA==";
export const POST_ADVISER_PHOTO_CODE =
  "VZzIKS44D78LREowqJwxIGcqMARTo0S8at76Cot/1mE65fax/6aHnQ==";
// export const FIND_PHYSICAL_PERSON_CODE =
//   "?code=C9o6ktVtVJa2g0Dyo4zG3NwDYFXLN1gGXGgj7GPNZwcjxx2aKafx0g==";
export const FIND_PHYSICAL_PERSON_CODE =
  "?code=s2YGmT2WIn36u3qmUAWMj6k3acBN5TpxaPqfH7UsRjspDTDXMd209g==";
export const CREATE_CASE_CODE =
  "?code=fXURYEP2pDqPToTncL/Ybyc8XWj9oF78PWgdba9stqhhnoSz7EIJ6Q==";
export const ADD_PHYSICAL_PERSON_CODE =
  "?code=jBPq4Cz/HV3o2/HREGnxVMm76WWPTsEPPD14hy1mxFhBioBXoqpeow==";
// export const PUT_PHYSICAL_PERSON_CODE =
//   "?code=vqttsKR2QC5dzytkG3qREBzwX9fMrHcVk2ixuaE7s6Ppadiu1bgyVQ==";
export const PUT_PHYSICAL_PERSON_CODE =
  "?code=U1ybMdS0/tE3WaHYA8b9eeqtyMpf5MOv3LjO4JcaeafviJ21aXvv8Q==";
export const CODIGO_LISTA_DOCUMENTACION =
  "jUE8ZM00udOApGk5yafb1UJ7Ap9t1dBWietr1Y0782jKx672sC4YTQ==";
export const CODIGO_AGREGAR_DOCUMENTO =
  "hLae8FEp4xFhlot90GyLX5Rpx13IyT9TztgvM0M/3HziQT0KCuZgEg==";
export const CODIGO_EDITAR_DOCUMENTO =
  "lSBsmTkmR1cIpQ05n0NQSBRLfHZaVDIJcri8wfljDQ91svKeeoTpiw==";
export const CODIGO_LISTAR_CLIENTE =
  "olOm39Bthtj1pJZVBm0Lsi2afKUf5dvjxtgj/xNpQ9/AONvEvaeq0A==";
export const CODIGO_AGREGAR_CLIENTE =
  "Hal4PSkuyNBTgefXeC7QRBCoZ7HJut5E1vXs3WxJ0aAQ9U/eC8jnfg==";
export const FIND_LEGAL_PERSON_CODE =
  "?code=JMazu6188Wq7h4wAFRNJ3qaLAzNw8p8s472VvkISxcwscIdCx/HVvw==";
export const ADD_LEGAL_PERSON_CODE =
  "?code=lIn5oRTAVEeErjJWqPDp3NgjZDdEU2miFDEvcF/Zkfy225kgTt6cMw==";
export const PUT_LEGAL_PERSON_CODE =
  "?code=Z0QJAwAitbscIPMA3YXJtO98XbSMSarIIaYLNrZMyaSpFBJMlnIfkw==";
