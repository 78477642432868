import React from "react";
import EstadoGeneralTable from "./estadoGeneralTable";
import { Card, CardContent } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Card className="card-box mb-4">
      <CardContent className="p-3">
        <EstadoGeneralTable />
      </CardContent>
    </Card>
  );
};
