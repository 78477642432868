import React, { Fragment } from "react";
import { CircleLoader } from "react-spinners";

export const LoadingInit = () => (
  <Fragment>
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <CircleLoader color={"#009B86"} loading={true} />
      </div>
      <div className="text-muted font-size-xl text-center pt-3">
        Porfavor aguarde
        <span className="font-size-lg d-block text-dark">
          Estamos cargando sus datos
        </span>
      </div>
    </div>
  </Fragment>
);
