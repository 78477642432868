import React from "react";
import { Button, Divider, Card, CardContent } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import { Alert } from "@material-ui/lab";
import InformacionCliente from "./InformacionCliente";
import Identificacion from "./Identificacion";
import DetallePersonaFisica from "./DetallePersonaFisica";
import Informacionlaboral from "./Informacionlaboral";
import DatosDeOcupacion from "./DatosDeOcupacion";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCase,
  ModifyPhysicalPerson,
} from "../../../../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";
import {
  CREATE_CASE_CODE,
  PUT_PHYSICAL_PERSON_CODE,
} from "../../../../../../constants/codes";
import authentication from "@kdpw/msal-b2c-react";
import { useHistory } from "react-router";

const WizardPersonaFisica = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState(new Set());
  const [skipped] = React.useState(new Set());
  const form = useSelector((state) => state.physicalPerson.form);
  const agregar = useSelector((state) => state.caso.agregar);
  const cedula = authentication.getIdToken().extension_CI;
  const history = useHistory();
  const caso = {
    ClientID: cedula,
  };
  const cod = CREATE_CASE_CODE;

  const getSteps = () => {
    return [
      "Información del cliente",
      "Identificación",
      "Detalle de persona física e información de dirección",
      "Información laboral y datos financieros",
      // "Datos de solicitud",
      "Datos de ocupación y de cargos públicos",
    ];
  };
  const steps = getSteps();

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <InformacionCliente form={form} />;
      case 1:
        return <Identificacion form={form} />;
      case 2:
        return <DetallePersonaFisica form={form} />;
      case 3:
        return <Informacionlaboral form={form} />;
      // case 4:
      //   return <DatosDeSolicitud form={form} />;
      case 4:
        return <DatosDeOcupacion form={form} />;
      default:
        return "Unknown step";
    }
  };

  const totalSteps = () => {
    return getSteps().length;
  };

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep()
      ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
      : activeStep + 1;

    // dispatch(
    //   ModifyPhysicalPerson(
    //     Resource.Query({
    //       [FIX_URL]: PUT_PHYSICAL_PERSON_CODE,
    //       ...form,
    //     })
    //   )
    // );

    setActiveStep(newActiveStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    // dispatch(CreateCase(agregar.update({ [FIX_URL]: cod, ...caso })));
    dispatch(
      ModifyPhysicalPerson(
        Resource.Query({
          [FIX_URL]: PUT_PHYSICAL_PERSON_CODE,
          ...form,
        })
      )
    );
    setTimeout(history.goBack, 1000);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted(new Set());
  //   setSkipped(new Set());
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const buttonProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    completed={isStepComplete(index)}
                    {...buttonProps}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div>{getStepContent(activeStep)}</div>
        </CardContent>
        <div className="card-footer  text-right">
          {allStepsCompleted() ? (
            <div>
              <Alert severity="success">
                Todos los datos han sido actualizados!
              </Alert>
            </div>
          ) : (
            <div>
              <Divider />
              <div className="py-4">
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="contained"
                    className="mr-3"
                    color="primary"
                  >
                    Atras
                  </Button>

                  {activeStep === totalSteps() - 1 ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleComplete}
                    >
                      Finalizar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className="mr-3"
                    >
                      Siguiente
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default WizardPersonaFisica;
