import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createStore from "./redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import authentication from "@kdpw/msal-b2c-react";

//import authentication from './B2c';

authentication.initialize({
  // you can user your b2clogin.com domain here, setting is optional, will default to this
  instance: "https://fortalezainmueblespyb2c.b2clogin.com/",
  // your B2C tenant, you can also user tenants GUID here
  tenant: "fortalezainmueblespyb2c.onmicrosoft.com",
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: "B2C_1_fortaleza",
  // the policy to use for password reset
  resetPolicy: "mypasswordresetpolicy",
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  applicationId: "99309a7e-512f-431c-8971-92f644ef92e0",
  //   // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: "sessionStorage",
  //   // the scopes you want included in the access token
  scopes: [
    "https://fortalezainmueblespyb2c.onmicrosoft.com/99309a7e-512f-431c-8971-92f644ef92e0/demo.read",
  ],
  //   // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  // redirectUri: "https://witty-island-0b000e610.azurestaticapps.net/",
  // redirectUri: "https://miinversion.fortalezainmuebles.com/",
  // redirectUri: "http://localhost:3000",
  redirectUri: "https://inversores.fortalezainmuebles.com",

  //   // optional, the URI to redirect to after logout
  postLogoutRedirectUri: "https://inversores.fortalezainmuebles.com",
  // postLogoutRedirectUri: "http://localhost:3000",
  //   // optional, default to true, set to false if you change instance
  validateAuthority: false,
  //   // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
  silentLoginOnly: false,
});

authentication.run(() => {
  ReactDOM.render(
    <Provider store={createStore()}>
        <App />
    </Provider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
