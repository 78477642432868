import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { AGREGAR_CLIENTE, LISTA_DE_CLIENTES } from "../actionTypes";
import {
  AgregarCliente,
  LimpiarCliente,
  ListaDeClientes,
  MostrarMensaje,
} from "../actions";
import { agregarClientes, capturarClientes } from "../api";
import { CODIGO_LISTAR_CLIENTE } from "../../constants/codes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const solicitarClientes = (action$) =>
  action$.pipe(
    ofType(LISTA_DE_CLIENTES),
    toParams,
    makeRequest(capturarClientes),
    map(ListaDeClientes)
  );

const agregarCliente = (action$) =>
  action$.pipe(
    ofType(AGREGAR_CLIENTE),
    toParams,
    makeRequest(agregarClientes),
    map(AgregarCliente)
  );

const onGuardar = (action$) =>
  action$.pipe(
    ofType(AGREGAR_CLIENTE),
    filter((action) => Resource.isData(action.payload)),
    mergeMap(() => [
      // ListaDeClientes(Resource.Query({ code: CODIGO_LISTAR_CLIENTE })),
      MostrarMensaje({
        open: true,
        mensaje: "Cliente agregado con éxito",
        tipo: "success",
      }),
      LimpiarCliente(),
    ])
  );

const onGuardarError = (action$) =>
  action$.pipe(
    ofType(AGREGAR_CLIENTE),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      ListaDeClientes(Resource.Query({ code: CODIGO_LISTAR_CLIENTE })),
      MostrarMensaje({
        open: true,
        mensaje: "Error al guardar el cliente",
        tipo: "error",
      }),
    ])
  );

export default combineEpics(
  solicitarClientes,
  agregarCliente,
  onGuardar,
  onGuardarError
);
