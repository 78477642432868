/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Avatar } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { atc } = props;

  return (
    <tr key={atc.ATCID}>
      <td className="text-left">
        <div className="d-flex align-items-center">
          <Avatar alt="Avatar" src={atc.ATCPhotoURL} className="mr-2" />
          <div>
            <a
              onClick={(e) => e.preventDefault()}
              className="font-weight-bold text-black"
              title={atc.ATCName}
            >
              {atc.ATCName} {atc.ATCLastname}
            </a>
          </div>
        </div>
      </td>
      <td className="text-left">{atc.ATCNumber}</td>
      <td className="text-left">{atc.ATCMail}</td>
    </tr>
  );
};
