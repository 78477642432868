import React, { useCallback } from "react";
import ROUTES from "../../../constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { CODIGO_EDITAR_DOCUMENTO } from "../../../constants/codes";
import { ModificarDocumento } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";

const Filas = (props) => {
  const { docu } = props;
  const dispatch = useDispatch();
  const cod = "?code=" + CODIGO_EDITAR_DOCUMENTO;

  const onChangeVisibility = useCallback(
    (event) => {
      event.preventDefault();
      const estado = () => {
        if (docu.DocumentState === "S") {
          return "N";
        }
        return "S";
      };
      const updateState = {
        ...docu,
        DocumentState: estado(),
      };
      dispatch(
        ModificarDocumento(
          Resource.Query({
            [FIX_URL]: docu.DocumentID + cod,
            ...updateState,
          })
        )
      );
    },
    [dispatch, docu, cod]
  );

  const visibility = () => {
    if (docu.DocumentState === "S") {
      return true;
    }
    return false;
  };

  return (
    <tr key={docu.DocumentID}>
      <td className="text-left">{docu.DocumentID}</td>
      <td className="text-left">{docu.DocumentName}</td>
      <td>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Estado"
              control={
                <Switch
                  size="small"
                  color="secondary"
                  checked={visibility()}
                  onChange={onChangeVisibility}
                />
              }
              label="Activo"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </td>

      <td>
        <Tooltip arrow title="Editar">
          <IconButton
            size="small"
            variant="outlined"
            color="secondary"
            component={Link}
            to={`${ROUTES.EDITAR_DOCUMENTO.replace(":id", docu.DocumentID)}`}
          >
            <FontAwesomeIcon icon={["fa", "edit"]} />
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
};

export default Filas;
