import React, { useCallback } from "react";
import FormCliente from "../FormCliente";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_AGREGAR_CLIENTE } from "../../../constants/codes";
import { useHistory } from "react-router-dom";
import { FIX_URL } from "ghost-stories/dist/constants";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  AgregarCliente,
  EditarCliente,
  LimpiarCliente,
} from "../../../redux/actions";

export const LogicaAgregarCliente = () => {
  const dispatch = useDispatch();
  const cod = "?code=" + CODIGO_AGREGAR_CLIENTE;
  const form = useSelector((state) => state.clientes.form);
  const history = useHistory();

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditarCliente({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onGuardar = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(AgregarCliente(Resource.Query({ [FIX_URL]: cod, ...form })));
      dispatch(LimpiarCliente({}));
      setTimeout(history.goBack, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  return (
    <>
      <FormCliente
        {...{
          title: "Agregar Cliente",
          form,
          onChangeField,
          onGuardar,
        }}
      />
    </>
  );
};

export default LogicaAgregarCliente;
