import React from "react";
import Headerinversiones from "../components/HeaderInversiones";
import Footer from "../components/Footer";
import TablaAmigos from "./TablaAmigos";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div>
      <Headerinversiones />
      <TablaAmigos />
      <Footer />
    </div>
  );
};
