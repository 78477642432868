import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { IconButton, Tooltip } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client }) => {
  return (
    <div>
      <div className="card-header">
        <div className="font-size-lg d-block" style={{ fontSize: 16 }}>
          <span className="text-black-50" style={{ fontSize: 16 }}>
            Ejecutivo/a de ATC:
          </span>{" "}
          {value.data.ATCName} {value.data.ATCLastname}
        </div>
        <div className="avatar-wrapper-overlap ml-3">
          <div className="avatar-icon-wrapper" placement="top">
            <div className="avatar-icon">
              <img alt="..." src={value.data.ATCPhotoURL} />
            </div>
          </div>
          <Tooltip arrow title="Whatsapp">
            <IconButton
              color="secondary"
              className="ml-3"
              href={"https://wa.me/" + value.data.ATCNumber}
              target="_blank"
            >
              <WhatsAppIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
