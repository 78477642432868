import {
  BASE_URL_PROD,
  AUTH_URL,
  AZURE_API_URL,
  GESDOC_URL,
  AZURE_API_URL_DEV,
} from "../../constants/vars";
import {
  qsGetter,
  jsonPoster,
  jsonPut,
} from "ghost-stories/dist/fetch/helpers";

//POST
export const captureAuthToken = jsonPoster(
  `${AUTH_URL}/login?code=OWTkae2DNfXYotoDjMM57PVByVBMIr/LRC/A7ufADALChWLf4CgaBw==`
);

//AZURE
//BUILDINGS
export const captureBuildings = qsGetter(`${AZURE_API_URL}/edificio`);
export const updateBuildings = jsonPut(`${AZURE_API_URL}/edificio`);
export const addBuildings = jsonPoster(`${AZURE_API_URL}/edificio`);
//ADVISER
export const captureAdviser = jsonPoster(`${AZURE_API_URL}/asesor/find`);
export const addAdviserPhoto = jsonPoster(`${AZURE_API_URL}/picture/upload`);
export const addAdvisers = jsonPoster(`${AZURE_API_URL}/asesor`);
export const captureAllAdvisers = qsGetter(`${AZURE_API_URL}/asesor`);
export const updateAdvisers = jsonPut(`${AZURE_API_URL}/asesor`);
//ATC
export const captureATC = jsonPoster(`${AZURE_API_URL}/atc/find`);
export const addATCs = jsonPoster(`${AZURE_API_URL}/atc`);
export const captureAllATC = qsGetter(`${AZURE_API_URL}/atc`);
export const updateATCs = jsonPut(`${AZURE_API_URL}/atc`);
//NOTIFICATIONS
export const saveNotifications = jsonPoster(`${AZURE_API_URL}/mensaje`);
export const captureNotificationsLogs = jsonPoster(
  `${AZURE_API_URL}/mensaje/find`
);
export const updateNotifications = jsonPut(`${AZURE_API_URL}/mensaje`);

// //PHYSICAL_PERSON
// export const capturePhysicalPersons = jsonPoster(
//   `${AZURE_API_URL}/cliente_datos/find`
// );
// export const addPhysicalPersons = jsonPoster(`${AZURE_API_URL}/personafisica`);
// export const updatePhysicalPersons = jsonPut(`${AZURE_API_URL}/personafisica`);

//PHYSICAL_PERSON
export const capturePhysicalPersons = jsonPoster(
  `${AZURE_API_URL_DEV}/client/find`
);
export const addPhysicalPersons = jsonPoster(`${AZURE_API_URL}/personafisica`);
export const updatePhysicalPersons = jsonPut(
  `${AZURE_API_URL_DEV}/client/update`
);
export const createCase = jsonPoster(`${AZURE_API_URL_DEV}/create-case`);

//LEGAL_PERSON
export const captureLegalPersons = jsonPoster(
  `${AZURE_API_URL}/persona_juridica/find`
);
export const addLegalPersons = jsonPoster(`${AZURE_API_URL}/personajuridica`);
export const updateLegalPersons = jsonPut(`${AZURE_API_URL}/personajuridica`);

//MICROTECH
export const captureContractDetail = jsonPoster(`${BASE_URL_PROD}/contrato`);
export const captureClients = jsonPoster(`${BASE_URL_PROD}/cliente`);
export const captureContracts = jsonPoster(`${BASE_URL_PROD}/contrato`);
export const captureNotifications = jsonPoster(`${BASE_URL_PROD}/mensajes`);

//DOCUMENTOS
export const agregarDocumentos = jsonPoster(`${GESDOC_URL}/documento`);
export const capturarDocumentos = qsGetter(`${GESDOC_URL}/documento`);
export const editarDocumento = jsonPut(`${GESDOC_URL}/documento`);

//CLIENTES/VENDEDOR
export const capturarClientes = jsonPoster(`${GESDOC_URL}/cliente/find`);
export const agregarClientes = jsonPoster(`${GESDOC_URL}/cliente`);
export const capturarDatosUsuario = jsonPoster(`${GESDOC_URL}/cliente/user`);
