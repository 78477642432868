import React from "react";
import LogicaEditar from "./LogicaEditar";

const Editar = () => {
  return (
    <>
      <LogicaEditar />
    </>
  );
};

export default Editar;
