import HomeIcon from "@material-ui/icons/Home";
import AssessmentIcon from "@material-ui/icons/Assessment";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import FaceIcon from "@material-ui/icons/Face";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    content: [
      {
        label: "Inicio",
        icon: HomeIcon,
        rol: "USER",
        content: [
          {
            label: "Tablero principal",
            to: "/",
            rol: "USER",
          },
        ],
      },
      {
        label: "Inicio",
        icon: HomeIcon,
        rol: "ADMINISTRATOR",
        content: [
          {
            label: "Tablero principal",
            to: "/",
            rol: "ADMINISTRATOR",
          },
        ],
      },
      {
        label: "Estado de cuentas",
        icon: AssessmentIcon,
        rol: "USER",
        content: [
          {
            label: "Detalle de contratos",
            to: "/detalle_contrato",
            rol: "USER",
          },
          {
            label: "Aportes",
            to: "/aportes",
            rol: "USER",
          },
        ],
      },
      {
        label: "Administrador",
        icon: VpnKeyIcon,
        rol: "ADMINISTRATOR",
        content: [
          {
            label: "Edificios",
            to: "/edificios",
            rol: "ADMINISTRATOR",
          },
          {
            label: "Asesores",
            to: "/asesores",
            rol: "ADMINISTRATOR",
          },
          {
            label: "ATC",
            to: "/atc",
            rol: "ADMINISTRATOR",
          },
          {
            label: "Documentos",
            to: "/gestion_documentos",
            rol: "ADMINISTRATOR",
          },
        ],
      },
      {
        label: "Vendedor",
        icon: FaceIcon,
        rol: "VENDEDOR",
        content: [
          {
            label: "Nuevos Clientes",
            to: "/clientes",
            rol: "VENDEDOR",
          },
        ],
      },
      {
        label: "Back Office",
        icon: FaceIcon,
        rol: "BACKOFFICE",
        content: [
          {
            label: "Gestión de documentos",
            to: "/documentos",
            rol: "BACKOFFICE",
          },
        ],
      },
    ],
  },
];
