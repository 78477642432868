import React, { useEffect } from "react";
import CardsContratos from "./CardsContratos";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import { useDispatch, useSelector } from "react-redux";
import { ObtainAdvisers } from "../../../redux/actions";
import { FIND_ADVISER_CODE } from "../../../constants/codes";
import { FIX_URL } from "ghost-stories/dist/constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, id, contracts, buttonText }) => {
  const dispatch = useDispatch();
  const codAdviser = "?code=" + FIND_ADVISER_CODE;
  const client = value.map((x) => x).find((xs) => xs.CustomerID === id);
  const advisers = useSelector((state) => state.adviser.list);
  useEffect(() => {
    dispatch(
      ObtainAdvisers(
        advisers.update({ [FIX_URL]: codAdviser, AdviserID: client.AdviserID })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ResourceRender
      resource={contracts}
      Data={CardsContratos}
      Query={OnQuery}
      Empty={OnEmpty}
      Error={OnError}
      props={{ client: client, buttonText: buttonText, advisers: advisers }}
    />
  );
};
