export const MOSTRAR_MENSAJE = "SIGNAL/MOSTRAR_MENSAJE";
export const LOGIN_GOOGLE = "AUTHENTICATION/LOGIN/GOOGLE";
export const LOGIN_FACEBOOK = "AUTHENTICATION/LOGIN/FACEBOOK";
export const LOGIN_TWITTER = "AUTHENTICATION/LOGIN/TWITTER";
export const LOGIN = "AUTHENTICATION/LOGIN";
export const REFRESCAR_PANTALLA = "SIGNAL/REFRESCAR_PANTALLA";

//TOKEN
export const OBTAIN_TOKEN = "AUTHENTICATION/OBTAIN_TOKEN";

//CLIENTS
export const CLIENTS = "USER/CLIENTS";

//CONTRACTS
export const CONTRACTS = "USER/CONTRACTS";
export const CONTRACT_DETAIL = "USER/CONTRACT_DETAIL";

//ADVISER
export const ADVISER = "USER/ADVISER";
export const ADD_ADVISER = "ADMINISTRATOR/ADD_ADVISER";
export const ADD_ADVISER_PHOTO = "ADMINISTRATOS/ADD_ADVISER_PHOTO";
export const ADVISER_ALL = "ADMINISTRATOR/ADVISER_ALL";
export const EDIT_ADVISER = "ADMINISTRATOR/EDIT_ADVISER";
export const MODIFY_ADVISER = "ADMINISTRATOR/MODIFY_ADVISER";
export const CLEAN_ADVISER = "ADMINISTARTOR/CLEAN_ADVISER";

//ATC
export const ATC = "USER/ATC";
export const ATC_ALL = "ADMINISTRATOR/ATC_ALL";
export const ADD_ATC = "ADMINISTRATOR/ADD_ATC";
export const EDIT_ATC = "ADMINISTRATOR/EDIT_ATC";
export const MODIFY_ATC = "ADMINISTRATOR/MODIFY_ATC";
export const CLEAN_ATC = "ADMINISTARTOR/CLEAN_ATC";

//BUILDINGS
export const BUILDINGS = "USER/BUILDINGS";
export const ADD_BUILD = "ADMINISTRATOR/ADD_BUILD";
export const EDIT_BUILDINGS = "ADMINISTRATOR/EDIT_BUILDINGS";
export const MODIFY_BUILD = "ADMINISTRADOR/MODIFY_BUILD";
export const CLEAN_BUILDINGS = "ADMINISTARTOR/CLEAN_BUILDINGS";

//NOTIFICATIONS
export const NOTIFICATIONS = "USER/NOTIFICATIONS";
export const NOTIFICATIONS_LOG = "USER/NOTIFICATIONS_LOG";
export const EDIT_NOTIFICATIONS = "USER/EDIT_NOTIFICATIONS";
export const MODIFY_NOTIFICATIONS = "USER/MODIFY_NOTIFICATIONS";
export const SAVE_NOTIFICATIONS = "USER/SAVE_NOTIFICATIONS";

//PHYSICAL_PERSON
export const PHYSICAL_PERSON = "USER/PHYSICAL_PERSON";
export const EDIT_PHYSICAL_PERSON = "USER/EDIT_PHYSICAL_PERSON";
export const ADD_PHYSICAL_PERSON = "USER/ADD_PHYSICAL_PERSON";
export const MODIFY_PHYSICAL_PERSON = "USER/MODIFY_PHYSICAL_PERSON";
export const CREATE_CASE = "USER/CREATE_CASE";

//LEGAL_PERSON
export const LEGAL_PERSON = "USER/LEGAL_PERSON";
export const EDIT_LEGAL_PERSON = "USER/EDIT_LEGAL_PERSON";
export const ADD_LEGAL_PERSON = "USER/ADD_LEGAL_PERSON";
export const MODIFY_LEGAL_PERSON = "USER/MODIFY_LEGAL_PERSON";

//DOCUMENTACIÓN
export const LISTA_DE_DOCUMENTOS = "ADMINISTRADOR/LISTA_DE_DOCUMENTOS";
export const AGREGAR_DOCUMENTO = "ADMINISTRADOR/AGREGAR_DOCUMENTO";
export const EDITAR_DOCUMENTO = "ADMINISTRADOR/EDITAR_DOCUMENTO";
export const MODIFICAR_DOCUMENTO = "ADMINISTRADOR/MODIFICAR_DOCUMENTO";
export const LIMPIAR_DOCUMENTOS = "ADMINISTRADOR/LIMPIAR_DOCUMENTOS";

//CLIENTES/VENDEDOR
export const LISTA_DE_CLIENTES = "VENDEDOR/LISTA_DE_CLIENTES";
export const AGREGAR_CLIENTE = "VENDEDOR/AGREGAR_CLIENTE";
export const EDITAR_CLIENTE = "VENDEDOR/EDITAR_CLIENTE";
export const LIMPIAR_CLIENTE = "VENDEDOR/LIMPIAR_CLIENTE";
