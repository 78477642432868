import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResourceRender from "ghost-stories/dist/react/resource";
import { Card, CardContent, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import ATCTable from "./ATCTable";
import { CleanATC, ObtainAllATC } from "../../../redux/actions";
import { Link } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import { ATC_CODE } from "../../../constants/codes";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const atc = useSelector((state) => state.atc.all);
  useEffect(() => {
    dispatch(ObtainAllATC(atc.update({ code: ATC_CODE })));
    dispatch(CleanATC({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2">
        <div className="card-header--title">
          <h6 className="font-size-lg mt-2 text-dark">ATC</h6>
        </div>
        <div className="card-header--actions">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            component={Link}
            to={ROUTES.ATC_AGREGAR}
          >
            Agregar
          </Button>
        </div>
      </div>
      <CardContent className="p-3">
        <ResourceRender
          resource={atc}
          Data={ATCTable}
          Query={OnQuery}
          Empty={OnError}
          Error={OnEmpty}
        />
      </CardContent>
    </Card>
  );
};
