import React from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

const Formulario = (props) => {
  const {
    title,
    onCancelar,
    form,
    onChangeField,
    onChangeVisibility,
    onGuardar,
    valid,
    botonIDReadOnly,
  } = props;

  const estado = () => {
    if (form.DocumentState === "S") {
      return true;
    }
    return false;
  };

  return (
    <>
      <form onSubmit={onGuardar}>
        <Card className="card-box mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title">
              <h6 className="font-size-lg mt-2 text-dark">{title}</h6>
            </div>
          </div>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  error={valid}
                  helperText={valid ? "El ID ya está en uso" : ""}
                  fullWidth
                  className="mb-3"
                  label="Código"
                  name="DocumentID"
                  value={form.DocumentID}
                  onChange={onChangeField}
                  InputProps={{
                    readOnly: botonIDReadOnly,
                  }}
                  variant="outlined"
                  required
                />
                <TextField
                  fullWidth
                  className="mb-3"
                  label="Descripción del documento a agregar"
                  name="DocumentName"
                  value={form.DocumentName}
                  onChange={onChangeField}
                  variant="outlined"
                  required
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="activo"
                      checked={estado()}
                      onChange={onChangeVisibility}
                    />
                  }
                  label="Activo"
                  className="mb-3"
                />
              </Grid>
            </Grid>
          </CardContent>
          <div className="card-footer p-3 text-right">
            <Button
              className="mr-3"
              size="small"
              color="primary"
              onClick={onCancelar}
              variant="contained"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button
              size="small"
              color="secondary"
              type="submit"
              variant="contained"
            >
              <span className="btn-wrapper--label">Guardar</span>
            </Button>
          </div>
        </Card>
      </form>
    </>
  );
};

export default Formulario;
