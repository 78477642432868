// lib imports
import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { PrivateRoute } from "./components/utils";
import MuiTheme from "./theme";
import ROUTES from "./constants/routes";
import Dashboard from "./pages/Dashboard";
import EstadoGeneral from "./pages/EstadoGeneral";
import Aportes from "./pages/Aportes";
import Detalle from "./pages/DetalleContrato";
import Amigos from "./pages/Amigos";
import MinimalLayout from "./layouts/Minimal";
import LeftSidebar from "./layouts/LeftSidebar";
import { ROLES, USER, ADMINISTRATOR, BACKOFFICE } from "./constants/roles";
import Edificios from "./pages/Edificios";
import EdificioAgregar from "./pages/Edificios/Agregar";
import EdificioEditar from "./pages/Edificios/Editar";
import Asesores from "./pages/Asesoria";
import AsesoresAgregar from "./pages/Asesoria/Agregar";
import AsesoresEditar from "./pages/Asesoria/Editar";
import ATC from "./pages/ATC";
import ATCAgregar from "./pages/ATC/Agregar";
import ATCEditar from "./pages/ATC/Editar";
import ActualizacionDatos from "./layouts/components/Sidebar/SidebarUserbox/Wizard";
import Documentacion from "./pages/Documentacion";
import AgregarDocumentacion from "./pages/Documentacion/Agregar";
import EditarDocumento from "./pages/Documentacion/Editar";
import NuevosClientes from "./pages/Clientes";
import AgregarCliente from "./pages/Clientes/Agregar";
import Documentos from "./pages/DescargaDocumentos";

import { useSelector } from "react-redux";

const ROUTES_COMPONENTS = [
  [ROUTES.DASHBOARD, Dashboard, ROLES],
  [ROUTES.ESTADO_GENERAL, EstadoGeneral, USER],
  [ROUTES.APORTES, Aportes, USER],
  [ROUTES.DETALLE_CONTRATO, Detalle, USER],
  [ROUTES.AMIGOS, Amigos, USER],
  [ROUTES.EDIFICIOS, Edificios, ADMINISTRATOR],
  [ROUTES.EDIFICIOS_AGREGAR, EdificioAgregar, ADMINISTRATOR],
  [ROUTES.EDIFICIOS_EDITAR, EdificioEditar, ADMINISTRATOR],
  [ROUTES.ASESORES, Asesores, ADMINISTRATOR],
  [ROUTES.ASESORES_AGREGAR, AsesoresAgregar, ADMINISTRATOR],
  [ROUTES.ASESORES_EDITAR, AsesoresEditar, ADMINISTRATOR],
  [ROUTES.ATC, ATC, ADMINISTRATOR],
  [ROUTES.ATC_AGREGAR, ATCAgregar, ADMINISTRATOR],
  [ROUTES.ATC_EDITAR, ATCEditar, ADMINISTRATOR],
  [ROUTES.ACTUALIZACION_DATOS, ActualizacionDatos, USER],
  [ROUTES.GESTION_DE_DOCUMENTOS, Documentacion, ADMINISTRATOR],
  [ROUTES.AGREGAR_DOCUMENTACION, AgregarDocumentacion, ADMINISTRATOR],
  [ROUTES.EDITAR_DOCUMENTO, EditarDocumento, ADMINISTRATOR],
  [ROUTES.NUEVOS_CLIENTES, NuevosClientes, ADMINISTRATOR],
  [ROUTES.AGREGAR_CLIENTE, AgregarCliente, ADMINISTRATOR],
  [ROUTES.DOCUMENTOS, Documentos, BACKOFFICE],
];

const PRIVATE_ROUTES = ROUTES_COMPONENTS.map(([route, _]) => route);

export const Routes = () => {
  const location = useLocation();
  const client = useSelector((state) => state.clients.list);

  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from={ROUTES.ROOT} to={ROUTES.DASHBOARD} />
        <Route path={[ROUTES.E404]}>
          <MinimalLayout>
            <Switch location={location} key={location.pathname}>
              <Route
                path={ROUTES.E404}
                component={() => <div>'This is the error page'</div>}
              />
            </Switch>
          </MinimalLayout>
        </Route>

        <Route exact path={PRIVATE_ROUTES}>
          <LeftSidebar>
            <Switch location={location} key={location.pathname}>
              {ROUTES_COMPONENTS.map(([route, Component, roles], index) => (
                <PrivateRoute
                  key={index.toString()}
                  exact
                  roles={roles}
                  path={route}
                  component={Component}
                  client={client}
                />
              ))}
            </Switch>
          </LeftSidebar>
        </Route>
      </Switch>
    </ThemeProvider>
  );
};
