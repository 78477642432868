import React, { useState } from "react";
import { Dialog, Tooltip, IconButton, Card } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { adviser } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal((shown) => !shown);

  return (
    <div>
      <Tooltip arrow title="Ver más">
        <IconButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={toggle}
        >
          <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={modal} onClose={toggle}>
        <Card className="card-box p-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-block p-0 avatar-icon-wrapper m-0 d-90">
                <div className="rounded-circle overflow-hidden">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={adviser.AdviserPhotoURL}
                  />
                </div>
              </div>
            </div>
            <h3 className="font-weight-bold mt-3">
              {adviser.AdviserName} {adviser.AdviserLastname}
            </h3>
            <p className="mb-0 text-black-50">
              {adviser.AdviserNumber}
              <Tooltip arrow title="Whatsapp">
                <IconButton
                  color="secondary"
                  href={"https://wa.me/" + adviser.AdviserNumber}
                  target="_blank"
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </p>
            <p className="mb-0 text-black-50">
              {adviser.AdviserMail}
              <Tooltip arrow title="Email">
                <IconButton
                  color="secondary"
                  href={"mailto:" + adviser.AdviserMail}
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>
            </p>
          </div>
        </Card>
      </Dialog>
    </div>
  );
};
