import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  ADD_PHYSICAL_PERSON,
  PHYSICAL_PERSON,
  MODIFY_PHYSICAL_PERSON,
} from "../actionTypes";
import {
  addPhysicalPersons,
  capturePhysicalPersons,
  updatePhysicalPersons,
} from "../api";
import {
  ADDPhysicalPerson,
  ModifyPhysicalPerson,
  ObtainPhysicalPerson,
  MostrarMensaje,
} from "../actions";

const listPhysicalPerson = (action$) =>
  action$.pipe(
    ofType(PHYSICAL_PERSON),
    toParams,
    makeRequest(capturePhysicalPersons),
    map(ObtainPhysicalPerson)
  );

const addPhysicalPerson = (action$) =>
  action$.pipe(
    ofType(ADD_PHYSICAL_PERSON),
    toParams,
    makeRequest(addPhysicalPersons),
    map(ADDPhysicalPerson)
  );

const updatePhysicalPerson = (action$) =>
  action$.pipe(
    ofType(MODIFY_PHYSICAL_PERSON),
    toParams,
    makeRequest(updatePhysicalPersons),
    map(ModifyPhysicalPerson)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_PHYSICAL_PERSON),
    filter((action) => Resource.isData(action.payload)),
    mergeMap(() => [
      MostrarMensaje({
        open: true,
        mensaje: "Datos guardados con éxito",
        tipo: "success",
      }),
    ])
  );

export default combineEpics(
  listPhysicalPerson,
  addPhysicalPerson,
  updatePhysicalPerson,
  onSaveEdit
);
