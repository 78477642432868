import React, { useCallback, useEffect } from "react";
import Formulario from "../Formulario";
import { CODIGO_EDITAR_DOCUMENTO } from "../../../constants/codes";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditarDocumento, ModificarDocumento } from "../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";

const LogicaEditar = () => {
  const cod = "?code=" + CODIGO_EDITAR_DOCUMENTO;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.documentos.form);

  const original = useSelector((state) =>
    state.documentos.lista.value.data
      .map((x) => x)
      .find((xs) => xs.DocumentID === id)
  );
  
  useEffect(() => {
    if (original === undefined) {
      return history.goBack();
    }
    dispatch(EditarDocumento(original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original]);

  const onCancelar = useCallback(() => {
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditarDocumento({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChangeVisibility = useCallback(
    (event) => {
      const estado = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditarDocumento({ ...form, DocumentState: estado() }));
    },
    [form, dispatch]
  );

  const onGuardar = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        ModificarDocumento(Resource.Query({ [FIX_URL]: id + cod, ...form }))
      );
      setTimeout(history.goBack, 100);
    },
    [dispatch, history, id, form, cod]
  );
  return (
    <>
      <Formulario
        {...{
          title: "Editar Documento",
          form,
          onCancelar,
          onChangeField,
          onChangeVisibility,
          onGuardar,
          botonIDReadOnly: true,
        }}
      />
    </>
  );
};

export default LogicaEditar;
