import { merge } from "remeda";
import {
  ADD_BUILD,
  BUILDINGS,
  CLEAN_BUILDINGS,
  EDIT_BUILDINGS,
} from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({ code: "" }),
  form: {
    BuildID: "",
    BuildName: "",
    BuildDate: "",
    BuildAlias: "",
    BuildURL: "",
    BuildVisibility: "S",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case BUILDINGS:
      return merge(state, { list: action.payload });
    case ADD_BUILD:
      return merge(state, { form: action.payload });
    case EDIT_BUILDINGS:
      return merge(state, { form: action.payload });
    case CLEAN_BUILDINGS:
      return merge(state, { form: initial.form });
    default:
      return state;
  }
};
