import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { EditPhysicalPerson } from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import {
  empresarioIndInit,
  ocupacionListInit,
} from "../../../../../../constants/helpers";

const InformacionCliente = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const [correo, setCorreo] = useState(false);
  // const [role, setRole] = useState(form.ClientJobRole);
  const [ocupacion, setOcupacion] = useState(form.ClientJobOccupation);
  const [empresarioIndTrue, setEmpresarioIndTrue] = useState(
    form.ClientMainActivities
  );
  // const [contactos, setContactos] = useState(form.ClientPreferredContactMethod);
  // const onChangeBoxRole = (i) => (e) => {
  //   let newArr = [...role];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setRole(newArr);
  // };

  const onChangeBoxOcupacion = (i) => (e) => {
    let newArr = [...ocupacionListInit];
    newArr[i] = {
      occupationId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setOcupacion(newArr);
  };

  const onChangeBoxActividad = (i) => (e) => {
    let newArr = [...empresarioIndInit];
    newArr[i] = {
      activityId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setEmpresarioIndTrue(newArr);
  };

  // const onChangeBoxContacto = (i) => (e) => {
  //   let newArr = [...contactos];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setContactos(newArr);
  // };

  const handleBirtDateChange = useCallback(
    (date) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          ClientBirtDate: date.toString(),
        })
      );
    },
    [form, dispatch]
  );

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChangeFieldCorreo = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
      setCorreo(true);
    },
    [form, dispatch]
  );

  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        // ClientJobRole: role,
        ClientJobOccupation: ocupacion,
        ClientMainActivities: empresarioIndTrue,
        // ClientPreferredContactMethod: contactos,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // role,
    ocupacion,
    empresarioIndTrue,
    // contactos,
  ]);

  return (
    <>
      <form className="mt-3">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Nombres"
              variant="outlined"
              value={form.ClientName}
              name="ClientName"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Apellidos"
              variant="outlined"
              value={form.ClientLastName}
              name="ClientLastName"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Teléfono Móvil"
              variant="outlined"
              value={form.ClientPhoneNumber}
              name="ClientPhoneNumber"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Fecha de nacimiento"
                value={form.ClientBirtDate}
                onChange={(date) => handleBirtDateChange(date)}
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              fullWidth
              className="mb-3"
              label="Correo"
              variant="outlined"
              value={form.ClientEmail}
              name="ClientEmail"
              onChange={onChangeFieldCorreo}
              required
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          {correo === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <Alert severity="warning">
                  Atención. Una vez cambiado el correo por el equipo de
                  Fortaleza Inmuebles, este usuario de login será desactivado y
                  tendrá que crear uno nuevo.
                </Alert>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}

          {/* <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              fullWidth
              className="mb-3"
              label="Rubro"
              variant="outlined"
              value={form.ClientWorkArea}
              name="ClientWorkArea"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid> */}

          <Grid item xs={12} sm={1}></Grid>
          {/* <Grid item xs={12} sm={3}>
            <>
              <div className="font-size-lg ">Rol</div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  {role.map((x, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChangeBoxRole(i)}
                          checked={x.state}
                          value={x.title}
                        />
                      }
                      label={x.title}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </>
          </Grid> */}
          <Grid item xs={12} sm={10}>
            <>
              <div className="font-size-lg ">Ocupación</div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="pr-4">
                <FormGroup row>
                  {ocupacion.map((x, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChangeBoxOcupacion(i)}
                          checked={x.state}
                          value={x.title}
                        />
                      }
                      label={x.title}
                    />
                  ))}
                  {ocupacion[ocupacion.length - 1].state === true ? (
                    <TextField
                      fullWidth
                      className="mb-3"
                      label="Indicar otra ocupación"
                      variant="outlined"
                      value={form.ClientAnotherOccupation}
                      name="ClientAnotherOccupation"
                      onChange={onChangeField}
                      required
                    />
                  ) : null}
                </FormGroup>
              </FormControl>
            </>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          {ocupacion[0].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <div className="font-size-lg ">
                  Seleccione la/s actividad/es principal/es a la que se dedica
                </div>
                <Divider className="my-4" />
                <FormControl component="fieldset" className="pr-4">
                  <FormGroup row>
                    {empresarioIndTrue.map((x, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onChangeBoxActividad(i)}
                            checked={x.state}
                            value={x.title}
                          />
                        }
                        label={x.title}
                      />
                    ))}

                    {empresarioIndTrue[empresarioIndTrue.length - 1].state ===
                    true ? (
                      <TextField
                        fullWidth
                        className="mb-3"
                        label="Indicar otra actividad"
                        variant="outlined"
                        value={form.ClientAnotherActivities}
                        name="ClientAnotherActivities"
                        onChange={onChangeField}
                        required
                      />
                    ) : null}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}
          {/* <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <>
              <div className="font-size-lg ">Método de contacto preferido</div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="pr-4">
                <FormGroup row>
                  {contactos.map((x, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChangeBoxContacto(i)}
                          checked={x.state}
                          value={x.title}
                        />
                      }
                      label={x.title}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </>
          </Grid>
          <Grid item xs={12} sm={1}></Grid> */}
        </Grid>
      </form>
    </>
  );
};

export default InformacionCliente;
