import { pipe } from "rxjs";
import { withLatestFrom, map } from "rxjs/operators";
import { mergeAll } from "remeda";

export const addAuth = (state$) =>
  pipe(
    withLatestFrom(
      state$.pipe(
        map((state) => {
          const token = state.tokens.list;
          const accesToken = token
            .map((value) => value.accessToken)
            .getDataOr("");
          return {
            options: {
              headers: {
                "auth-token": accesToken,
              },
            },
          };
        })
      )
    ),
    map(mergeAll)
  );
