import React from "react";
import {
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PanelDetail from "./components/PanelDetail";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value }) => {
  const detail = value[0];
  return (
    <Grid container spacing={4} className="mt-3">
      <Grid item xs={12} lg={12}>
        <Card className="card-box">
          <List tag="div">
            <ListItem tag="div" className="p-0 d-block">
              <CardContent className="p-3">
                <div>
                  {value.map((x) => (
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <div className="text-center">
                          <span className="text-black-50 d-block">
                            Fecha de ingreso
                          </span>
                          <b className="font-size-xxl text-primary">
                            {x.BeginContractDate}
                          </b>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <div className="text-center">
                          <span className="text-black-50 d-block">
                            Fecha de fin de contrato
                          </span>
                          <b
                            className="font-size-xxl"
                            style={{ color: "#007F79" }}
                          >
                            {x.EndContractDate}
                          </b>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <div>
                  {detail.GroupData.map((x) => (
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography>{x.FeeGroup}</Typography>
                      </ExpansionPanelSummary>
                      <PanelDetail
                        TypeOwnerFees={[x.TypeOwnerFees].filter(
                          (x) => x.Amount !== 0
                        )}
                        TypePureOwnerFees={[x.TypePureOwnerFees].filter(
                          (x) => x.Amount !== 0
                        )}
                        TypeWaitingFees={[x.TypeWaitingFees].filter(
                          (x) => x.Amount !== 0
                        )}
                        TypeExtraordinaryFees={[x.TypeExtraordinaryFees].filter(
                          (x) => x.Amount !== 0
                        )}
                        TypeOtherFees={[x.TypeOtherFees].filter(
                          (x) => x.Amount !== 0
                        )}
                      />
                    </ExpansionPanel>
                  ))}
                </div>
              </CardContent>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};
