import React, { useState } from "react";
import { Hidden, IconButton, Box, Popover, Divider } from "@material-ui/core";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  return (
    <div className="d-flex align-items-left popover-header-wrapper">
      <Hidden smDown>
        <Box component="span">
          <IconButton
            onClick={handleClick1}
            color="inherit"
            className="btn-inverse mx-1 d-50"
          >
            <NotificationsActiveTwoToneIcon />
          </IconButton>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              paper: "app-header-dots",
            }}
          >
            <div className="popover-custom-xl overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                <div className="bg-composed-wrapper--content text-light px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notificaciones
                  </h4>
                </div>
              </div>
              <div className="height-280 mt-3">
                <div className="text-center my-2">
                  <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                      <FontAwesomeIcon
                        icon={["fas", "times"]}
                        className="d-flex align-self-center display-3"
                      />
                    </div>
                  </div>
                  <h6 className="font-weight-bold font-size-lg mb-1 mt-4 text-black">
                    No tienes notificaciones
                  </h6>
                </div>
              </div>
              <Divider />
              <div className="text-center py-3"></div>
            </div>
          </Popover>
        </Box>
      </Hidden>
    </div>
  );
};
