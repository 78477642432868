import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  ADD_LEGAL_PERSON,
  LEGAL_PERSON,
  MODIFY_LEGAL_PERSON,
} from "../actionTypes";
import {
  addLegalPersons,
  captureLegalPersons,
  updateLegalPersons,
} from "../api";
import {
  ADDLegalPerson,
  ModifyLegalPerson,
  ObtainLegalPerson,
  MostrarMensaje,
} from "../actions";

const listLegalPerson = (action$) =>
  action$.pipe(
    ofType(LEGAL_PERSON),
    toParams,
    makeRequest(captureLegalPersons),
    map(ObtainLegalPerson)
  );

const addLegalPerson = (action$) =>
  action$.pipe(
    ofType(ADD_LEGAL_PERSON),
    toParams,
    makeRequest(addLegalPersons),
    map(ADDLegalPerson)
  );

const updateLegalPerson = (action$) =>
  action$.pipe(
    ofType(MODIFY_LEGAL_PERSON),
    toParams,
    makeRequest(updateLegalPersons),
    map(ModifyLegalPerson)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_LEGAL_PERSON),
    filter((action) => Resource.isData(action.payload)),
    mergeMap(() => [
      MostrarMensaje({
        open: true,
        mensaje: "Datos guardados con éxito",
        tipo: "success",
      }),
    ])
  );

export default combineEpics(
  listLegalPerson,
  addLegalPerson,
  updateLegalPerson,
  onSaveEdit
);
