import React, { useEffect } from "react";
import HeaderInversionesCard from "./HeaderInversionesCard";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import { useDispatch, useSelector } from "react-redux";
import { ObtainAdvisers, ObtainATCS } from "../../redux/actions";
import { FIX_URL } from "ghost-stories/dist/constants";
import { FIND_ADVISER_CODE, FIND_ATC_CODE } from "../../constants/codes";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, id, contracts }) => {
  const dispatch = useDispatch();
  const codAdviser = "?code=" + FIND_ADVISER_CODE;
  const codATC = "?code=" + FIND_ATC_CODE;
  const client = value.map((x) => x).find((xs) => xs.CustomerID === id);
  const advisers = useSelector((state) => state.adviser.list);
  useEffect(() => {
    dispatch(
      ObtainAdvisers(
        advisers.update({ [FIX_URL]: codAdviser, AdviserID: client.AdviserID })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const atcs = useSelector((state) => state.atc.list);
  useEffect(() => {
    dispatch(
      ObtainATCS(atcs.update({ [FIX_URL]: codATC, AtcID: client.ATCID }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResourceRender
      resource={contracts}
      Data={HeaderInversionesCard}
      Query={OnQuery}
      Empty={OnEmpty}
      Error={OnError}
      props={{ client: client, advisers: advisers, atcs: atcs }}
    />
  );
};
