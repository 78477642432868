import { merge } from "remeda";
import {
  EDIT_NOTIFICATIONS,
  NOTIFICATIONS,
  NOTIFICATIONS_LOG,
  SAVE_NOTIFICATIONS,
} from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({ nrodocumento: "" }),
  log: Resource.Empty({ CustomerID: "" }),
  form: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case NOTIFICATIONS:
      return merge(state, { list: action.payload });
    case NOTIFICATIONS_LOG:
      return merge(state, { log: action.payload });
    case EDIT_NOTIFICATIONS:
      return merge(state, { form: action.payload });
    case SAVE_NOTIFICATIONS:
      return merge(state, { form: action.payload });
    default:
      return state;
  }
};
