/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Card, CardContent } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { contributions } = props;

  const color = (state) => {
    if (state === "Cancelado") {
      return "#007F79";
    }
    if (state === "Pendiente") {
      return "#B83236";
    }
    return "#9F9F9F";
  };
  return (
    <Fragment>
      <Card className="card-box mb-4">
        <div className="card-header">
          <div className="card-header--title">
            <b>Aportes</b>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="table table-striped table-hover text-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="text-left">Concepto - Tipo</th>
                  <th className="text-left">Fecha - Estado</th>
                  <th className="text-left">Monto</th>
                  <th className="text-left">Pagado</th>
                </tr>
              </thead>
              {contributions.map((x) => (
                <tbody key={x.FeeConcept}>
                  <tr>
                    <td>
                      <div className="d-flex align-items-left">
                        <div>
                          <a
                            className="font-weight-bold text-black"
                            title="..."
                          >
                            {x.FeeConcept}
                          </a>
                          <span className="text-black-50 d-block">
                            {x.FeeType}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className="text-left">
                      <div>
                        <div className="font-weight-bold text-black d-block">
                          {x.FeeDueDate}
                        </div>
                        <div
                          className="h-auto py-0 px-3 badge"
                          style={{
                            backgroundColor: color(x.FeeStatus),
                            color: "#fff",
                          }}
                        >
                          {x.FeeStatus}
                        </div>
                      </div>
                    </td>

                    <td className="text-left">
                      <div>
                        <a
                          className="font-weight-bold text-black"
                          title="Monto"
                          style={{ fontSize: 16 }}
                        >
                          {x.FeeGrossAmount}
                        </a>

                        <span
                          className="text-black d-block"
                          title="Descuento"
                          style={{ fontSize: 12 }}
                        >
                          Desc.: {x.FeeDiscountAmount}
                        </span>

                        <span
                          className="text-black d-block"
                          title="Multa"
                          style={{ fontSize: 12 }}
                        >
                          Multa: {x.FeePenaltyAmount}
                        </span>
                      </div>
                    </td>

                    <td className="text-left">
                      <a
                        className="font-weight-bold text-black"
                        title="..."
                        style={{ fontSize: 16 }}
                      >
                        {x.FeeAmount}
                      </a>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};
