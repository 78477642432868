import React, { useCallback, useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { EditarCliente, LimpiarCliente } from "../../../../redux/actions";
import authentication from "@kdpw/msal-b2c-react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const UrlPinDialog = (props) => {
  const { form, states, onGuardar, cedula } = props;
  var crypto = require("crypto");
  const timeStamp = new Date().getTime().toString();
  const md5 = crypto
    .createHash("md5")
    .update(timeStamp)
    .digest("hex");
  const url =
    "https://documentos.fortalezainmuebles.com?ref=" +
    md5 +
    "&id=" +
    cedula;
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [state] = useState({
    state1: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
  });
  const mensaje =
    "Bienvenido a fortaleza inmuebles!\n" +
    "En esta dirección puede subir con seguridad los documentos solicitados: \n" +
    url +
    "\nEl pin para el ingreso a esta dirección es: " +
    state.state1 +
    "\nPor seguridad no comparta ni la dirección ni el pin";

  const onClicks = () => {
    dispatch(
      EditarCliente({
        ...form,
        ClientRequestedDocuments: JSON.stringify(states),
        ClientPIN: state.state1.toString(),
        ClientAccessURL: url,
        VendorID: authentication.getIdToken().aud,
      })
    );
  };

  const toggle = () => {
    onClicks();
    setModal((modal) => !modal);
  };

  const onCancelar = useCallback(() => {
    dispatch(LimpiarCliente({}));
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        className="mr-3"
        size="small"
        color="primary"
        onClick={onCancelar}
        variant="contained"
      >
        <span className="btn-wrapper--label">Cancelar</span>
      </Button>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={toggle}
      >
        <span className="btn-wrapper--label">Guardar</span>
      </Button>
      <Dialog open={modal} onClose={toggle} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">Url y pin</DialogTitle>
        <DialogContent className="mb-2 mt-2">
          <TextField
            className="mb-3"
            variant="outlined"
            label="URL"
            value={form.ClientAccessURL}
            fullWidth
          />

          <TextField
            variant="outlined"
            value={state.state1}
            label="PIN"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <CopyToClipboard text={mensaje} onCopy={() => setOpen(true)}>
            <Button className="mr-3 mb-3" variant="contained" color="primary">
              Copiar datos
            </Button>
          </CopyToClipboard>
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Datos copiados al portapapeles
            </Alert>
          </Snackbar>

          <Button
            className="mr-3 mb-3"
            variant="contained"
            color="secondary"
            onClick={onGuardar}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
