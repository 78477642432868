import { createMuiTheme } from '@material-ui/core/styles';
import typography from './typography';
import { esES } from '@material-ui/core/locale';

const vars = {
  primary: '#ED601C',
  primaryLight: '#495368',
  primaryDark: '#09142a',
  secondary: '#007F79',
  inheritDefault1: '#fefefe',
  inheritDefault2: '#f8f9ff',
  second: '#070919',
  indigo: '#7420ff',
  purple: '#793de6',
  pink: '#fc26a4',
  red: '#f83245',
  orange: '#f4772e',
  yellow: '#ffc926',
  green: '#1bc943',
  teal: '#18e1a5',
  cyan: '#27dcf3'
};
const MuiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: vars.primary
      },
      grey: {
        500: vars.inheritDefault1,
        A100: vars.inheritDefault2
      },
      secondary: {
        main: vars.secondary
      },
      error: {
        main: vars.red
      },
      success: {
        main: vars.green
      },
      warning: {
        main: vars.orange
      },
      contrastThreshold: 3,
      tonalOffset: 0.1
    },
    shape: {
      borderRadius: 5
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: vars.second
        }
      }
    },
    ...typography
  },
  esES
);

export default MuiTheme;
