import React, { useEffect } from "react";
import Footer from "../components/Footer";
import HeaderBienvenida from "../components/HeaderBienvenida";
import EdificiosFortaleza from "./components/EdificiosFortaleza";
import Contratos from "./components/Contratos";
import TipoAportes from "./components/TipoAportes";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ObtainBuildings, ObtainContracts } from "../../redux/actions";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import authentication from "@kdpw/msal-b2c-react";
import { BUILD_CODE } from "../../constants/codes";
import { LoadingGeneral } from "../../utils/LoadingGeneral";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const id_token = authentication.getIdToken();
  const dispatch = useDispatch();
  const id = id_token.extension_CI;
  const clients = useSelector((state) => state.clients.list);
  const contracts = useSelector((state) => state.contracts.list);
  useEffect(() => {
    dispatch(
      ObtainContracts(contracts.update({ tipoConsulta: "CustomerID", nro: id }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildings = useSelector((state) => state.buildings.list);
  useEffect(() => {
    dispatch(ObtainBuildings(buildings.update({ code: BUILD_CODE })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (buildings === undefined || "") {
    return <LoadingGeneral />;
  }

  return (
    <div>
      <HeaderBienvenida />
      <ResourceRender
        resource={buildings}
        Data={EdificiosFortaleza}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <ResourceRender
            resource={clients}
            Data={Contratos}
            Query={OnQuery}
            Empty={OnEmpty}
            Error={OnError}
            props={{ id: id, contracts: contracts }}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <ResourceRender
            resource={clients}
            Data={TipoAportes}
            Query={OnQuery}
            Empty={OnEmpty}
            Error={OnError}
            props={{ id: id, contracts: contracts }}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};
