import React from "react";
import WizardPersonaFisica from "./WizardPersonaFisica";
import WizardPersonaJuridica from "./WizardPersonaJuridica";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const persona = "fisica";
  // const dispatch = useDispatch();
  // const physicalPerson = useSelector((state) => state.physicalPerson.list);
  // const form = useSelector((state) => state.physicalPerson.form);
  // const legalPerson = useSelector((state) => state.legalPerson.list);
  // const formJ = useSelector((state) => state.legalPerson.form);

  // const isData = () => {
  //   if (persona === "fisica") {
  //     if (Object.keys(physicalPerson.value.data).length === 0) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     if (Object.keys(legalPerson.value.data).length === 0) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (persona === "fisica") {
  //     if (isData() === false) {
  //       dispatch(
  //         ADDPhysicalPerson(
  //           Resource.Query({ [FIX_URL]: ADD_PHYSICAL_PERSON_CODE, ...form })
  //         )
  //       );
  //     }
  //   } else {
  //     if (isData() === false) {
  //       dispatch(
  //         ADDLegalPerson(
  //           Resource.Query({ [FIX_URL]: ADD_LEGAL_PERSON_CODE, ...formJ })
  //         )
  //       );
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {persona === "fisica" ? (
        <WizardPersonaFisica />
      ) : (
        <WizardPersonaJuridica />
      )}
    </>
  );
};
