import React from "react";
import { Card } from "@material-ui/core";
import Circle from "react-circle";
import CountUp from "react-countup";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client }) => {
  const contract = value
    .map((x) => x)
    .filter((xs) => xs.CustomerID === client.CustomerID);
  return (
    <div>
      {contract.map((x) => (
        <Card
          key={x.ContractNumber}
          className="card-box rounded-sm p-3 mt-3 mx-4 mb-3 "
        >
          <div className="d-flex align-items-center">
            <Circle
              animate={true} // Boolean: Animated/Static progress
              animationDuration="3s" //String: Length of animation
              responsive={false} // Boolean: Make SVG adapt to parent size
              size={80} // Number: Defines the size of the circle.
              lineWidth={26} // Number: Defines the thickness of the circle's stroke.
              progress={((x.TotalFees / x.TotalFeesContract) * 100).toFixed()} // Number: Update to change the progress and percentage.
              progressColor="#ED601C" // String: Color of "progress" portion of circle.
              bgColor="#edeef1" // String: Color of "empty" portion of circle.
              textColor="#ED601C" // String: Color of percentage text color.percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
              textStyle={{
                fontSize: "60px",
                fontWeight: "bold",
              }}
              roundedStroke={true} // Boolean: Rounded/Flat line ends
              showPercentage={true} // Boolean: Show/hide percentage.
              showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
            />
            <div className="pl-3">
              <div className="text-second">
                Contrato número: {x.ContractNumber}
              </div>
              <div className="font-weight-bold display-4 py-1 text-second">
                Gs.
                <span>
                  <CountUp
                    start={0}
                    end={parseInt(x.TotalPaymentsGs)}
                    duration={3}
                    deplay={2}
                    separator="."
                    decimals={0}
                    decimal=","
                  />
                </span>
              </div>
              <div className="text-second font-size-sm ">
                {x.TotalFees}/{x.TotalFeesContract}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
