import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResourceRender from "ghost-stories/dist/react/resource";
import { Card, CardContent } from "@material-ui/core";
import { OnQuery, OnError, OnEmpty } from "../../../ResourceHelpers";
import AsesoresTable from "./AsesoresTable";
import { CleanAdviser, ObtainAllAdvisers } from "../../../../redux/actions";
import { ADVISER_CODE } from "../../../../constants/codes";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const advisers = useSelector((state) => state.adviser.all);
  useEffect(() => {
    dispatch(ObtainAllAdvisers(advisers.update({ code: ADVISER_CODE })));
    dispatch(CleanAdviser({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2">
        <div className="card-header--title">
          <h6 className="font-size-lg mt-2 text-dark">Asesores/as</h6>
        </div>
      </div>
      <CardContent className="p-3">
        <ResourceRender
          resource={advisers}
          Data={AsesoresTable}
          Query={OnQuery}
          Empty={OnError}
          Error={OnEmpty}
        />
      </CardContent>
    </Card>
  );
};
