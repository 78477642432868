import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Tooltip,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import VerMas from "./VerMas";
import { Link } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import { useDispatch } from "react-redux";
import { ModifyBuild } from "../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";
import { PUT_BUILD_CODE } from "../../../constants/codes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { edificio } = props;
  const dispatch = useDispatch();
  const cod = "?code=" + PUT_BUILD_CODE;

  const onChangeVisibility = useCallback(
    (event) => {
      event.preventDefault();
      const valor = () => {
        if (edificio.BuildVisibility === "S") {
          return "N";
        }
        return "S";
      };
      const updateVisibility = {
        ...edificio,
        BuildVisibility: valor(),
      };
      dispatch(
        ModifyBuild(
          Resource.Query({
            [FIX_URL]: edificio.BuildID + cod,
            ...updateVisibility,
          })
        )
      );
    },
    [dispatch, edificio, cod]
  );

  const visibility = () => {
    if (edificio.BuildVisibility === "S") {
      return true;
    }
    return false;
  };
  return (
    <tr key={edificio.BuildID}>
      <td className="text-left">{edificio.BuildName}</td>
      <td className="text-left">{edificio.BuildAlias}</td>
      <td className="text-left">{edificio.BuildDate}</td>
      <td>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="publico"
              control={
                <Switch
                  size="small"
                  color="secondary"
                  checked={visibility()}
                  onChange={onChangeVisibility}
                />
              }
              label="Activo"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </td>
      <td>
        <Tooltip arrow title="Editar">
          <IconButton
            size="small"
            variant="outlined"
            color="secondary"
            component={Link}
            to={`${ROUTES.EDIFICIOS_EDITAR.replace(":id", edificio.BuildID)}`}
          >
            <FontAwesomeIcon icon={["fa", "edit"]} />
          </IconButton>
        </Tooltip>
      </td>
      <td className="text-center">
        <VerMas edificio={edificio} />
      </td>
    </tr>
  );
};
