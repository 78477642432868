import React, { useCallback } from "react";
import FormEdificios from "../FormEdificios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADDBuild, EditBuildings } from "../../../redux/actions";
import { POST_BUILD_CODE } from "../../../constants/codes";
import { FIX_URL } from "ghost-stories/dist/constants";
import { Resource } from "ghost-stories/dist/unions/Resource";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const cod = "?code=" + POST_BUILD_CODE;
  const form = useSelector((state) => state.buildings.form);
  const history = useHistory();
  const onCancelar = () => {
    history.goBack();
  };

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditBuildings({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChangeDate = useCallback(
    (event) => {
      dispatch(
        EditBuildings({
          ...form,
          BuildDate: parseInt(event.target.value),
        })
      );
    },
    [form, dispatch]
  );

  const onChageVisibility = useCallback(
    (event) => {
      const valor = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditBuildings({ ...form, BuildVisibility: valor() }));
    },
    [form, dispatch]
  );

  const onSave = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(ADDBuild(Resource.Query({ [FIX_URL]: cod, ...form })));
      setTimeout(history.goBack, 100);
    },
    [dispatch, form, history, cod]
  );
  return (
    <FormEdificios
      {...{
        title: "Agregar Edificio",
        onCancelar,
        form,
        onChangeField,
        onChangeDate,
        onChageVisibility,
        onSave,
      }}
    />
  );
};
