/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dialog, Card, Divider, Avatar, Button } from "@material-ui/core";
import avatar from "../../../../assets/images/avatars/avatar.png";
import authentication from "@kdpw/msal-b2c-react";
import ROUTES from "../../../../constants/routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditPhysicalPerson } from "../../../../redux/actions";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value }) => {
  const persona = "fisica";
  const [modal1, setModal1] = useState(false);
  const dispatch = useDispatch();
  const toggle1 = () => setModal1(!modal1);
  const id_token = authentication.getIdToken();
  const email = id_token.emails[0];
  const Name = id_token.given_name;
  const LastName = id_token.family_name;
  const cedula = authentication.getIdToken().extension_CI;
  const cliente = value.map((x) => x).find((xs) => xs.CustomerID === cedula);
  // const form = useSelector((state) => state.physicalPerson.form);
  const physicalPerson = useSelector((state) => state.physicalPerson.list);
  // const formJ = useSelector((state) => state.legalPerson.form);
  // const legalPerson = useSelector((state) => state.legalPerson.list);

  useEffect(() => {
    if (persona === "fisica") {
      dispatch(EditPhysicalPerson(physicalPerson.value.data[0]));
    }
    // else {
    //   if (Object.keys(legalPerson.value.data).length === 0) {
    //     dispatch(
    //       EditLegalPerson({
    //         ...formJ,
    //         ClientID: authentication.getIdToken().extension_CI,
    //         ClientLegalStructure: JSON.stringify(estructuraJuridicaInit),
    //         ClientHeading: JSON.stringify(rubroInit),
    //         ClientSwornDeclaration: JSON.stringify(declaracionJuradaInit),
    //         ClientAcceptsTruthOfTheInformation: JSON.stringify(veracidadInfo),
    //         ClientHeIsAShareholder: JSON.stringify(declaracionJuradaPEPInit),
    //       })
    //     );
    //   } else {
    //     dispatch(EditLegalPerson(legalPerson.value.data));
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Avatar
        alt="Remy Sharp"
        src={avatar}
        onClick={toggle1}
        className="app-sidebar-userbox-avatar"
      />
      <Dialog fullWidth open={modal1} onClose={toggle1}>
        <Card className="card-box p-4">
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-icon-wrapper rounded-circle mr-3">
                <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                  <div className="rounded-circle overflow-hidden">
                    <img alt="..." className="img-fluid" src={avatar} />
                  </div>
                </div>
              </div>
              <div className="w-100">
                <a className="font-weight-bold font-size-lg" title="...">
                  {Name} {LastName}
                </a>
                <span className="text-black-50 d-block">
                  {parseInt(cliente.CustomerCI).toLocaleString()}
                </span>
              </div>
            </div>
            <Divider />
            <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Correo:</span>
                <span className="text-black-50">{email}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Teléfono particular:</span>
                <span className="text-black-50">{cliente.CustomerPhone}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Categoria:</span>
                <span className="text-black-50">
                  {cliente.CustomerCategory}
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer  text-right">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className="mt-2"
              onClick={toggle1}
              component={Link}
              to={ROUTES.ACTUALIZACION_DATOS}
            >
              <span className="btn-wrapper--label">Actualizar Datos</span>
            </Button>
          </div>
        </Card>
      </Dialog>
    </div>
  );
};
