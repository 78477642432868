import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  CleanBuildings,
  EditBuildings,
  ModifyBuild,
} from "../../../redux/actions";
import FormEdificios from "../FormEdificios";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";
import { PUT_BUILD_CODE } from "../../../constants/codes";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const cod = "?code=" + PUT_BUILD_CODE;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.buildings.form);
  const original = useSelector((state) =>
    state.buildings.list.value.data
      .map((x) => x)
      .find((xs) => xs.BuildID === id)
  );
  useEffect(() => {
    if (original === undefined) {
      return history.goBack();
    }
    dispatch(EditBuildings(original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original]);
  const onCancelar = useCallback(() => {
    dispatch(CleanBuildings({}));
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditBuildings({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChangeDate = useCallback(
    (event) => {
      dispatch(
        EditBuildings({
          ...form,
          BuildDate: parseInt(event.target.value),
        })
      );
    },
    [form, dispatch]
  );

  const onChageVisibility = useCallback(
    (event) => {
      const valor = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditBuildings({ ...form, BuildVisibility: valor() }));
    },
    [form, dispatch]
  );

  const onSave = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(ModifyBuild(Resource.Query({ [FIX_URL]: id + cod, ...form })));
      setTimeout(history.goBack, 100);
    },
    [dispatch, history, id, form, cod]
  );
  return (
    <FormEdificios
      {...{
        title: "Editar Edificio",
        form,
        onCancelar,
        onChangeField,
        onChageVisibility,
        onSave,
        onChangeDate,
      }}
    />
  );
};
