import React, { useEffect, useState } from "react";
import ROUTES from "../../../constants/routes";
import AddIcon from "@material-ui/icons/Add";
import NuevosClientesTable from "./NuevosClientesTable";
import ResourceRender from "ghost-stories/dist/react/resource";
import { Card, CardContent, Button, Grid, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  LimpiarCliente,
  ListaDeClientes,
  ListaDeDocumentos,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  CODIGO_LISTAR_CLIENTE,
  CODIGO_LISTA_DOCUMENTACION,
} from "../../../constants/codes";
import { FIX_URL } from "ghost-stories/dist/constants";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import authentication from "@kdpw/msal-b2c-react";

const Tabla = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cod = "?code=" + CODIGO_LISTAR_CLIENTE;
  const clientes = useSelector((state) => state.clientes.lista);
  const documentos = useSelector((state) => state.documentos.lista);
  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroCI, setFiltroCI] = useState("");

  const onChangeFiltroNombre = (event) => {
    setFiltroNombre(event.target.value);
  };

  const onChangeFiltroCI = (event) => {
    setFiltroCI(event.target.value);
  };

  useEffect(() => {
    dispatch(
      ListaDeClientes(
        clientes.update({
          [FIX_URL]: cod,
          VendorID: authentication.getIdToken().aud,
        })
      )
    );
    dispatch(
      ListaDeDocumentos(documentos.update({ code: CODIGO_LISTA_DOCUMENTACION }))
    );
    dispatch(LimpiarCliente({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAgregar = (event) => {
    event.preventDefault();
    history.push(`${ROUTES.AGREGAR_CLIENTE}`);
  };

  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2">
        <div className="card-header--title">
          <h6 className="font-size-lg mt-2 text-dark">Nuevos Clientes</h6>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                className="mb-3"
                label="Filtrar por nombre"
                onChange={onChangeFiltroNombre}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                className="mb-3"
                label="Filtrar por CI"
                onChange={onChangeFiltroCI}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </div>
        <div className="card-header--actions">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={onAgregar}
            startIcon={<AddIcon />}
          >
            Agregar
          </Button>
        </div>
      </div>
      <CardContent className="p-3">
        {clientes.value === undefined ? (
          <OnQuery />
        ) : (
          <ResourceRender
            resource={clientes}
            Data={NuevosClientesTable}
            Query={OnQuery}
            Empty={OnError}
            Error={OnEmpty}
            props={{ filtroNombre: filtroNombre, filtroCI: filtroCI }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Tabla;
