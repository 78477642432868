import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { IconButton, Tooltip, Grid } from "@material-ui/core";
import ATCS from "./ATCS";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client, atcs }) => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <div className="card-header">
            <div className="font-size-lg d-block" style={{ fontSize: 16 }}>
              <span className="text-black-50" style={{ fontSize: 16 }}>Asesor/a:</span>{" "}
              {value.data.AdviserName} {value.data.AdviserLastname}
            </div>
            <div className="avatar-wrapper-overlap ml-3">
              <div className="avatar-icon-wrapper" placement="top">
                <div className="avatar-icon">
                  <img alt="..." src={value.data.AdviserPhotoURL} />
                </div>
              </div>
              <Tooltip arrow title="Whatsapp">
                <IconButton
                  color="secondary"
                  className="ml-3"
                  href={"https://wa.me/" + value.data.AdviserNumber}
                  target="_blank"
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} lg={6}>
          <ResourceRender
            resource={atcs}
            Data={ATCS}
            Query={OnQuery}
            Empty={OnEmpty}
            Error={OnError}
            props={{ client: client }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
