import React from "react";
import {
  Grid,
  Card,
  TextField,
  CardContent,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Avatar,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: "110px",
    height: "110px",
  },
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const {
    title,
    form,
    onCancelar,
    onChangeField,
    onChageState,
    onSave,
    onChangePhoto,
  } = props;
  const classes = useStyles();

  const valor = () => {
    if (form.ATCState === "S") {
      return true;
    }
    return false;
  };

  return (
    <form onSubmit={onSave}>
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <h6 className="font-size-lg mt-2 text-dark">{title}</h6>
          </div>
        </div>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={form.ATCPhotoURL}
                      className={classes.small}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    color="secondary"
                    component="label"
                    variant="contained"
                  >
                    Cambiar foto
                    <input
                      type="file"
                      name="image"
                      id="file"
                      onChange={onChangePhoto}
                      style={{ display: "none" }}
                    ></input>
                  </Button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                className="mb-3"
                label="ID"
                variant="outlined"
                value={form.AtcID}
                onChange={onChangeField}
                name="AtcID"
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Nombre"
                variant="outlined"
                name="ATCName"
                onChange={onChangeField}
                value={form.ATCName}
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Apellido"
                variant="outlined"
                name="ATCLastname"
                onChange={onChangeField}
                value={form.ATCLastname}
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Número de contacto"
                variant="outlined"
                name="ATCNumber"
                onChange={onChangeField}
                value={form.ATCNumber}
                helperText="Agregar contacto sin puntos ni espacios."
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Correo electrónico"
                variant="outlined"
                name="ATCMail"
                onChange={onChangeField}
                value={form.ATCMail}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <FormControlLabel
                control={
                  <Switch
                    name="activo"
                    checked={valor()}
                    onChange={onChageState}
                  />
                }
                label="Activo"
                className="mb-3"
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </CardContent>
        <div className="card-footer p-3 text-right">
          <Button
            className="mr-3"
            size="small"
            color="primary"
            variant="outlined"
            onClick={onCancelar}
          >
            <span className="btn-wrapper--label">Cancelar</span>
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
          >
            <span className="btn-wrapper--label">Guardar</span>
          </Button>
        </div>
      </Card>
    </form>
  );
};
