export const rol = [
  { title: "Responsable de tomar decisiones", state: false },
  { title: "Empleado", state: false },
  { title: "Persona con influencia", state: false },
];

export const ocupacionListInit = [
  { occupationId: 1, title: "Empresario indep.", state: false },
  { occupationId: 2, title: "Empleado privado", state: false },
  { occupationId: 3, title: "Empleado público", state: false },
  { occupationId: 4, title: "Jubilado privado.", state: false },
  { occupationId: 5, title: "Jubilado público.", state: false },
  { occupationId: 6, title: "Ama de casa", state: false },
  { occupationId: 7, title: "Estudiante", state: false },
  { occupationId: 8, title: "Desocupado", state: false },
  { occupationId: 9, title: "Otro", state: false },
];

export const empresarioIndInit = [
  { activityId: 1, title: "Agricultura", state: false },
  { activityId: 2, title: "Ganadería", state: false },
  { activityId: 3, title: "Industria manufacturera", state: false },
  { activityId: 4, title: "Construcción", state: false },
  { activityId: 5, title: "Comercio en gral.", state: false },
  { activityId: 6, title: "Servicios alojamiento y de comida", state: false },
  { activityId: 7, title: "Transporte y almacenamiento", state: false },
  { activityId: 8, title: "Venta seguros", state: false },
  { activityId: 9, title: "Act. inmobiliarias y de alquiler", state: false },
  { activityId: 10, title: "Sociales y de salud human", state: false },
  { activityId: 11, title: "Consultoría", state: false },
  { activityId: 12, title: "Otro", state: false },
];

export const contacto = [
  { title: "E-mail", state: false },
  { title: "Teléfono", state: false },
  { title: "Cualquiera", state: false },
];

export const tipoDocInit = [
  { title: "C.I. Paraguaya", state: false },
  { title: "CRC-Doc. Extranjero", state: false },
  { title: "Pasaporte", state: false },
];

export const rucInit = [
  { title: "Si", state: false },
  { title: "No", state: false },
];

export const sexoInit = [
  { genderId: 1, title: "Masculino", state: false },
  { genderId: 2, title: "Femenino", state: false },
];

export const estudiando = [
  { title: "Si", state: false },
  { title: "No", state: false },
];

export const pagandoCasa = [
  { title: "Si", state: false },
  { title: "No", state: false },
];

export const estudios = [
  { title: "Secundario", state: false },
  { title: "Universitario", state: false },
  { title: "Post Grado", state: false },
];

export const estadoCivilInit = [
  { title: "Soltero", state: false },
  { title: "Casado", state: false },
  { title: "Divorciado", state: false },
  { title: "Viudo", state: false },
];

export const situacionDeVivienda = [
  { title: "Propietario", state: false },
  { title: "Inquilino", state: false },
  { title: "Casa de Familia", state: false },
  { title: "Ninguno", state: false },
];

export const antiguedad = [
  { title: "1 Año", state: false },
  { title: "1 a 3 Años", state: false },
  { title: ">3 Años", state: false },
];

export const tipoIngreso = [
  { title: "Asalariado", state: false },
  { title: "Comisionista", state: false },
  { title: "Mixto", state: false },
  { title: "Prof. Independiente", state: false },
  { title: "Por Renta", state: false },
];

export const promedioIngresos = [
  { title: "> 2.000.000 Gs.", state: false },
  { title: "2.000.000 a 5.000.000 Gs.", state: false },
  { title: "5.000.000 a 10.000.000 Gs.", state: false },
  { title: "10.000.000 a 15.000.000 Gs.", state: false },
  { title: "15.000.000 a 25.000.000 Gs.", state: false },
  { title: "< 25.000.000 Gs.", state: false },
];

export const otrasInversiones = [
  { title: "Si", state: false },
  { title: "No", state: false },
];

export const otrasInversionesList = [
  { title: "Ahorro programado", state: false },
  { title: "Plazo fijo CDA", state: false },
  { title: "Inmuebles", state: false },
  { title: "Bonos (Bolsa de valores)", state: false },
  { title: "Auto", state: false },
  { title: "Otros", state: false },
];

export const fortalezaInfo = [
  { title: "Eventos", state: false },
  { title: "Web", state: false },
  { title: "Llamada", state: false },
  { title: "Whatsapp", state: false },
  { title: "Radio", state: false },
  { title: "Prensa escrita", state: false },
  { title: "Obras", state: false },
  { title: "Activaciones", state: false },
  { title: "Redes", state: false },
  { title: "Correo", state: false },
  { title: "Cartel", state: false },
  { title: "Referido", state: false },
];

export const tipoIngresoSistema = [
  { title: "Inscripción", state: false },
  { title: "Reactivación", state: false },
  { title: "Subasta", state: false },
  { title: "Mercado Secundario", state: false },
];

export const motivoDeIngreso = [
  { title: "Vivienda Propia", state: false },
  { title: "Futuro/Hijos", state: false },
  { title: "Inversión", state: false },
  { title: "Ahorro", state: false },
  { title: "Jubilación", state: false },
];

export const perfilInversion = [
  { title: "Corto plazo 1 a 5 años", state: false },
  { title: "Mediano Plazo 6 a 15 años", state: false },
  { title: "Largo Plazo hasta 20 años", state: false },
];

export const formaDePago = [
  { title: "Débito Automático", state: false },
  { title: "Depósito", state: false },
  { title: "Bocas de Pago", state: false },
];

export const envioNotificaciones = [
  { title: "Dirección Particular", state: false },
  { title: "Dirección Laboral", state: false },
];

export const fechaCobro = [
  { title: "1", state: false },
  { title: "2", state: false },
  { title: "3", state: false },
  { title: "4", state: false },
  { title: "5", state: false },
  { title: "6", state: false },
  { title: "7", state: false },
  { title: "8", state: false },
  { title: "9", state: false },
  { title: "10", state: false },
];

export const cargoPublicoInit = [
  { title: "SI", state: false },
  { title: "NO", state: false },
];

export const conyugeInit = [
  { title: "SI", state: false },
  { title: "NO", state: false },
];

export const personaCercanaInit = [
  { title: "SI", state: false },
  { title: "NO", state: false },
];

export const socioAccionistaInit = [
  { title: "SI", state: false },
  { title: "NO", state: false },
];

export const altaGerenciaInit = [
  { title: "SI", state: false },
  { title: "NO", state: false },
];

export const declaracionJuradaInit = [
  {
    title:
      "Ingresos/Ahorros producto de la actividad laboral o comercial u otra actividad lícita",
    state: false,
  },
  {
    title: "Venta de bienes muebles o inmuebles",
    state: false,
  },
  {
    title: "Herencia, legado o donación",
    state: false,
  },
  {
    title: "Ingresos de terceros",
    state: false,
  },
  {
    title: "Otros",
    state: false,
  },
];

export const declaracionJuradaPEPInit = [
  {
    title:
      "Es un socio/accionista con más del 10% del capital accionario/cuota de la Empresa un PEP? Si la respuesta es si, favor brindar información sobre nombre completo del socio/accionista, nombre de la institución pública y el cargo público que ostenta u ostentó y fechas de inicio/cese en la función pública.",
    state: false,
  },
  {
    title:
      "Es algún pariente de un socio/accionista/gerente de la Empresa un PEP? Si la respuesta es si, favor brindar información sobre nombre completo del socio/accionista, nombre completo del pariente y grado de parentesco, nombre de la institución pública y el cargo público que ostenta u ostentó y fechas de inicio/cese en la función pública.",
    state: false,
  },
];

export const estructuraJuridicaInit = [
  { title: "Sociedad Anónima", state: false },
  { title: "Entidad Sin Fines de Lucro", state: false },
  { title: "Fideicomiso", state: false },
  { title: "Sociedad de Responsabilidad Limitada", state: false },
  { title: "Persona Jurídica no residente en el país", state: false },
  { title: "Otro", state: false },
];

export const rubroInit = [
  { title: "Agricultura", state: false },
  { title: "Financiero", state: false },
  { title: "Industria manufacturera", state: false },
  { title: "Servicio electricidad y gas", state: false },
  { title: "Construcción", state: false },
  { title: "Comercio", state: false },
  { title: "Servicios alojamiento y de comida", state: false },
  { title: "Transporte y almacenamiento", state: false },
  { title: "Sector seguros", state: false },
  { title: "Enseñanza", state: false },
  { title: "Servicios sociales y de salud humana", state: false },
  { title: "Actividades Inmobiliarias y alquiler", state: false },
  { title: "Otro", state: false },
];

export const veracidadInfo = [{ title: "Acepto la declaración", state: false }];
