import React from "react";
import { LinearProgress } from "@material-ui/core";
import Dialogo from "./Components/Dialogo";

const Fila = (props) => {
  const { cliente } = props;
  const estadoDocumentos = JSON.parse(cliente.ClientRequestedDocuments);
  const cantSolicitado = estadoDocumentos.filter((x) => x.estado === true)
    .length;
  const docSubidos = estadoDocumentos.filter((x) => x.sent === true);
  const porcentaje = (docSubidos.length / cantSolicitado) * 100;

  return (
    <tr key={cliente.ClientID}>
      <td>
        <div className="d-flex align-items-left">
          <div>
            <a className="font-weight-bold text-black" title="..." href="#/">
              {cliente.ClientName}
            </a>
            <span className="text-black-50 d-block">
              {cliente.ClientLastName}
            </span>
          </div>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-left">
          <div>
            <a className="font-weight-bold text-black" title="..." href="#/">
              {Intl.NumberFormat().format(cliente.ClientCI)}
            </a>
            <span className="text-black-50 d-block">
              <div>
                <div className="d-flex mb-1 font-weight-bold justify-content-between font-size-sm">
                  <div className="text-uppercase opacity-6"></div>
                  <div>{porcentaje.toFixed()}%</div>
                </div>
                {porcentaje.toFixed() === "100" ? (
                  <LinearProgress
                    color="secondary"
                    variant="determinate"
                    value={porcentaje.toFixed()}
                  />
                ) : (
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={porcentaje.toFixed()}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
      </td>

      <td>
        <Dialogo
          cliente={cliente}
          docSubidos={docSubidos}
          porcentaje={porcentaje}
        />
      </td>
    </tr>
  );
};

export default Fila;
