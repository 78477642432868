import { merge } from "remeda";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  AGREGAR_CLIENTE,
  EDITAR_CLIENTE,
  LIMPIAR_CLIENTE,
  LISTA_DE_CLIENTES,
} from "../actionTypes";

const initial = {
  lista: Resource.Empty({ VendorID: "" }),
  form: {
    ClientID: new Date().getTime().toString(),
    ClientName: "",
    ClientLastName: "",
    ClientCI: "",
    ClientPIN: "",
    ClientAccessURL: "",
    ClientRequestedDocuments: [],
    VendorID: "",
  },
};

const clientes = (state = initial, action) => {
  switch (action.type) {
    case LISTA_DE_CLIENTES:
      return merge(state, { lista: action.payload });
    case AGREGAR_CLIENTE:
      return merge(state, { form: action.payload });
    case EDITAR_CLIENTE:
      return merge(state, { form: action.payload });
    case LIMPIAR_CLIENTE:
      return merge(state, { form: initial.form });
    default:
      return state;
  }
};

export default clientes;
