/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Tooltip,
  Avatar,
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@material-ui/core";
import VerMas from "./VerMas";
import { Link } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import { PUT_ATC_CODE } from "../../../constants/codes";
import { useDispatch } from "react-redux";
import { ModifyATC } from "../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();
  const { atc } = props;
  const cod = "?code=" + PUT_ATC_CODE;

  const onChangeState = useCallback(
    (event) => {
      event.preventDefault();
      const valor = () => {
        if (atc.ATCState === "S") {
          return "N";
        }
        return "S";
      };
      const updateState = {
        ...atc,
        ATCState: valor(),
      };
      dispatch(
        ModifyATC(
          Resource.Query({
            [FIX_URL]: atc.AtcID + cod,
            ...updateState,
          })
        )
      );
    },
    [dispatch, atc, cod]
  );

  const state = () => {
    if (atc.ATCState === "S") {
      return true;
    }
    return false;
  };
  return (
    <tr key={atc.AtcID}>
      <td className="text-left">
        <div className="d-flex align-items-center">
          <Avatar alt="Avatar" src={atc.ATCPhotoURL} className="mr-2" />
          <div>
            <a
              onClick={(e) => e.preventDefault()}
              className="font-weight-bold text-black"
              title={atc.ATCName}
            >
              {atc.ATCName} {atc.ATCLastname}
            </a>
          </div>
        </div>
      </td>
      <td className="text-left">{atc.ATCNumber}</td>
      <td className="text-left">{atc.ATCMail}</td>
      <td>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="publico"
              control={
                <Switch
                  size="small"
                  color="secondary"
                  checked={state()}
                  onChange={onChangeState}
                  name="checkedA"
                />
              }
              label="Activo"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </td>
      <td>
        <Tooltip arrow title="Editar">
          <IconButton
            size="small"
            variant="outlined"
            color="secondary"
            component={Link}
            to={`${ROUTES.ATC_EDITAR.replace(":id", atc.AtcID)}`}
          >
            <FontAwesomeIcon icon={["fa", "edit"]} />
          </IconButton>
        </Tooltip>
      </td>
      <td className="text-center">
        <VerMas atc={atc} />
      </td>
    </tr>
  );
};
