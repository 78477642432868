import { merge } from "remeda";
import {
  ADD_ADVISER,
  ADD_ADVISER_PHOTO,
  ADVISER,
  ADVISER_ALL,
  CLEAN_ADVISER,
  EDIT_ADVISER,
} from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({ AdviserID: "" }),
  all: Resource.Empty({ code: "" }),
  profilePic: Resource.Empty({}),
  form: {
    AdviserID: "",
    AdviserName: "",
    AdviserLastname: "",
    AdviserNumber: "",
    AdviserMail: "",
    AdviserPhotoURL: "https://i.postimg.cc/gJ0N0ry5/avatar.png",
    AdviserState: "S",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case ADVISER:
      return merge(state, { list: action.payload });
    case ADD_ADVISER_PHOTO:
      return merge(state, { profilePic: action.payload });
    case ADD_ADVISER:
      return merge(state, { form: action.payload });
    case ADVISER_ALL:
      return merge(state, { all: action.payload });
    case EDIT_ADVISER:
      return merge(state, { form: action.payload });
    case CLEAN_ADVISER:
      return merge(state, { form: initial.form });
    default:
      return state;
  }
};
