import React, { useEffect } from "react";
import Detalle from "./Detalle";
import HeaderInversiones from "../components/HeaderInversiones";
import Footer from "../components/Footer";
import Contratos from "./components/Contratos";
import { useDispatch, useSelector } from "react-redux";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import { ObtainContracts } from "../../redux/actions";
import authentication from "@kdpw/msal-b2c-react";
import { Loading } from "../../utils/Loading";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const id_token = authentication.getIdToken();
  const id = id_token.extension_CI;
  const clients = useSelector((state) => state.clients.list);
  const contracts = useSelector((state) => state.contracts.list);
  useEffect(() => {
    dispatch(
      ObtainContracts(contracts.update({ tipoConsulta: "CustomerID", nro: id }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const details = useSelector((state) => state.contracts.detail);

  return (
    <div>
      <ResourceRender
        resource={clients}
        Data={HeaderInversiones}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
        props={{ contracts: contracts, id: id }}
      />
      <ResourceRender
        resource={clients}
        Data={Contratos}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
        props={{
          buttonText: "Ver detalles",
          contracts: contracts,
          id: id,
        }}
      />
      <ResourceRender
        resource={details}
        Data={Detalle}
        Query={Loading}
        Empty={() => ""}
        Error={OnError}
        props={{ contracts: contracts, id: id }}
      />
      <Footer />
    </div>
  );
};
