import React from "react";
import { Card } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Card className="card-box mt-3" style={{ backgroundColor: "#007F79" }}>
      <div className=" font-size-sm p-3 rounded-sm">
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold" style={{ color: "white" }}>
            Quesada Nro 4.926 Edificio Atlas Center, Piso 4
          </span>
          <span className="font-weight-bold" style={{ color: "white" }}>
            (+59521) 614 412 R.A.
          </span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font-weight-bold" style={{ color: "white" }}>
            info@fortalezainmuebles.com
          </span>
          <span className="font-weight-bold" style={{ color: "white" }}>
            Asunción - Paraguay
          </span>
        </div>
      </div>
    </Card>
  );
};
