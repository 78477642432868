import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  MODIFY_NOTIFICATIONS,
  NOTIFICATIONS,
  NOTIFICATIONS_LOG,
  SAVE_NOTIFICATIONS,
} from "../actionTypes";
import {
  captureNotifications,
  captureNotificationsLogs,
  saveNotifications,
  updateNotifications,
} from "../api";
import {
  ModifyNotifications,
  ObtainNotifications,
  ObtainNotificationsLog,
  SaveNotifications,
  MostrarMensaje,
} from "../actions";
import { addAuth } from "../api/streams";

const listNotifications = (action$, state$) =>
  action$.pipe(
    ofType(NOTIFICATIONS),
    toParams,
    addAuth(state$),
    makeRequest(captureNotifications),
    map(ObtainNotifications)
  );

const listNotificationsLogs = (action$, state$) =>
  action$.pipe(
    ofType(NOTIFICATIONS_LOG),
    toParams,
    addAuth(state$),
    makeRequest(captureNotificationsLogs),
    map(ObtainNotificationsLog)
  );

const saveNotification = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTIFICATIONS),
    toParams,
    makeRequest(saveNotifications),
    map(SaveNotifications)
  );

const updateNotification = (action$) =>
  action$.pipe(
    ofType(MODIFY_NOTIFICATIONS),
    toParams,
    makeRequest(updateNotifications),
    map(ModifyNotifications)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_NOTIFICATIONS),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      MostrarMensaje({
        open: true,
        mensaje: "Mensaje marcado como leído",
        tipo: "success",
      }),
    ])
  );

export default combineEpics(
  listNotifications,
  saveNotification,
  listNotificationsLogs,
  updateNotification,
  onSaveEdit
);
