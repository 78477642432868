import React from "react";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Divider } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const {
    TypeOwnerFees,
    TypeWaitingFees,
    TypeExtraordinaryFees,
    TypeOtherFees,
    TypePureOwnerFees,
  } = props;
  const details = [];
  const detail = details.concat(
    TypeOwnerFees,
    TypePureOwnerFees,
    TypeWaitingFees,
    TypeExtraordinaryFees,
    TypeOtherFees
  );
  return (
    <div>
      {detail.map((x) => (
        <ExpansionPanelDetails className="d-block p-0">
          <div className="px-3 pt-3">
            <Grid container spacing={4}>
              <Grid xs={12} sm={4} item>
                <div className="bg-secondary p-3 text-center rounded">
                  <div>
                    <FontAwesomeIcon
                      icon={["far", "check-circle"]}
                      className="font-size-xxl text-warning"
                    />
                  </div>
                  <div className="mt-2 line-height-sm">
                    <span className="text-black-50 d-block">Tipo</span>
                    <b className="font-size-lg">{x.Type}</b>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={4} item>
                <div className="bg-secondary p-3 text-center rounded">
                  <div>
                    <FontAwesomeIcon
                      icon={["fas", "sort-amount-up"]}
                      className="font-size-xxl text-success"
                    />
                  </div>
                  <div className="mt-2 line-height-sm">
                    <span className="text-black-50 d-block">Cantidad</span>
                    <b className="font-size-lg">{x.Amount}</b>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={4} item>
                <div className="bg-secondary p-3 text-center rounded">
                  <div>
                    <FontAwesomeIcon
                      icon={["fas", "money-bill"]}
                      className="font-size-xxl text-info"
                    />
                  </div>
                  <div className="mt-2 line-height-sm">
                    <span className="text-black-50 d-block">Total</span>
                    <b className="font-size-lg">{x.Total}</b>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Divider className="my-3" />
        </ExpansionPanelDetails>
      ))}
    </div>
  );
};
