import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import {
  ADD_ADVISER,
  ADD_ADVISER_PHOTO,
  ADVISER,
  ADVISER_ALL,
  MODIFY_ADVISER,
} from "../actionTypes";
import {
  addAdviserPhoto,
  addAdvisers,
  captureAdviser,
  captureAllAdvisers,
  updateAdvisers,
} from "../api";
import {
  ModifyAdviser,
  ObtainAdvisers,
  ObtainAllAdvisers,
  MostrarMensaje,
  ADDAdviser,
  ADDAdviserPhoto,
} from "../actions";
import { ADVISER_CODE } from "../../constants/codes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const addAdviser = (action$) =>
  action$.pipe(
    ofType(ADD_ADVISER),
    toParams,
    makeRequest(addAdvisers),
    map(ADDAdviser)
  );

const onSave = (action$) =>
  action$.pipe(
    ofType(ADD_ADVISER),
    filter((action) => Resource.isQuery(action.payload)),
    mergeMap(() => [
      ObtainAllAdvisers(Resource.Query({ code: ADVISER_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "Asesor/a agregado con éxito",
        tipo: "success",
      }),
    ])
  );

const listAdviser = (action$) =>
  action$.pipe(
    ofType(ADVISER),
    toParams,
    makeRequest(captureAdviser),
    map(ObtainAdvisers)
  );

const listAdviserAll = (action$) =>
  action$.pipe(
    ofType(ADVISER_ALL),
    toParams,
    makeRequest(captureAllAdvisers),
    map(ObtainAllAdvisers)
  );

const updateAdviser = (action$) =>
  action$.pipe(
    ofType(MODIFY_ADVISER),
    toParams,
    makeRequest(updateAdvisers),
    map(ModifyAdviser)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_ADVISER),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      ObtainAllAdvisers(Resource.Query({ code: ADVISER_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "Asesor/a editado con éxito",
        tipo: "success",
      }),
    ])
  );

const changeAdviserPhoto = (action$) =>
  action$.pipe(
    ofType(ADD_ADVISER_PHOTO),
    toParams,
    makeRequest(addAdviserPhoto),
    map(ADDAdviserPhoto)
  );
export default combineEpics(
  listAdviser,
  listAdviserAll,
  updateAdviser,
  onSaveEdit,
  addAdviser,
  onSave,
  changeAdviserPhoto
);
