import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { CLIENTS } from "../actionTypes";
import { captureClients } from "../api";
import { ObtainClients } from "../actions";
import { addAuth } from "../api/streams";

const listClients = (action$, state$) =>
  action$.pipe(
    ofType(CLIENTS),
    toParams,
    addAuth(state$),
    makeRequest(captureClients),
    map(ObtainClients)
  );
export default combineEpics(listClients);
