import {
  MOSTRAR_MENSAJE,
  LOGIN,
  LOGIN_GOOGLE,
  LOGIN_TWITTER,
  LOGIN_FACEBOOK,
  CLIENTS,
  CONTRACTS,
  CONTRACT_DETAIL,
  ADVISER,
  ATC,
  BUILDINGS,
  NOTIFICATIONS,
  EDIT_BUILDINGS,
  CLEAN_BUILDINGS,
  ADVISER_ALL,
  EDIT_ADVISER,
  CLEAN_ADVISER,
  ATC_ALL,
  EDIT_ATC,
  CLEAN_ATC,
  OBTAIN_TOKEN,
  MODIFY_BUILD,
  ADD_BUILD,
  MODIFY_ADVISER,
  ADD_ADVISER,
  MODIFY_ATC,
  ADD_ATC,
  SAVE_NOTIFICATIONS,
  NOTIFICATIONS_LOG,
  EDIT_NOTIFICATIONS,
  MODIFY_NOTIFICATIONS,
  REFRESCAR_PANTALLA,
  ADD_ADVISER_PHOTO,
  PHYSICAL_PERSON,
  EDIT_PHYSICAL_PERSON,
  ADD_PHYSICAL_PERSON,
  MODIFY_PHYSICAL_PERSON,
  LISTA_DE_DOCUMENTOS,
  AGREGAR_DOCUMENTO,
  EDITAR_DOCUMENTO,
  MODIFICAR_DOCUMENTO,
  LIMPIAR_DOCUMENTOS,
  LISTA_DE_CLIENTES,
  AGREGAR_CLIENTE,
  EDITAR_CLIENTE,
  LIMPIAR_CLIENTE,
  LEGAL_PERSON,
  EDIT_LEGAL_PERSON,
  ADD_LEGAL_PERSON,
  MODIFY_LEGAL_PERSON,
  CREATE_CASE,
} from "./actionTypes";

const create = (type) => (payload) => ({ type, payload });
const empty = (type) => () => ({ type });
export const Login = create(LOGIN);
export const LoginWithGoogle = create(LOGIN_GOOGLE);
export const LoginWithTwitter = create(LOGIN_TWITTER);
export const LoginWithFacebook = create(LOGIN_FACEBOOK);
export const MostrarMensaje = create(MOSTRAR_MENSAJE);
export const refrescarPantalla = empty(REFRESCAR_PANTALLA);

//TOKEN
export const ObtainToken = create(OBTAIN_TOKEN);

//CLIENTS
export const ObtainClients = create(CLIENTS);

//CONTRACTS
export const ObtainContracts = create(CONTRACTS);
export const ObtainContractDetail = create(CONTRACT_DETAIL);

//ADVISER
export const ObtainAdvisers = create(ADVISER);
export const ADDAdviser = create(ADD_ADVISER);
export const ADDAdviserPhoto = create(ADD_ADVISER_PHOTO);
export const ObtainAllAdvisers = create(ADVISER_ALL);
export const EditAdviser = create(EDIT_ADVISER);
export const ModifyAdviser = create(MODIFY_ADVISER);
export const CleanAdviser = create(CLEAN_ADVISER);

//ATC
export const ObtainATCS = create(ATC);
export const ObtainAllATC = create(ATC_ALL);
export const ADDATC = create(ADD_ATC);
export const EditATC = create(EDIT_ATC);
export const ModifyATC = create(MODIFY_ATC);
export const CleanATC = create(CLEAN_ATC);

//BUILDINGS
export const ObtainBuildings = create(BUILDINGS);
export const ADDBuild = create(ADD_BUILD);
export const EditBuildings = create(EDIT_BUILDINGS);
export const ModifyBuild = create(MODIFY_BUILD);
export const CleanBuildings = create(CLEAN_BUILDINGS);

//NOTIFICATIONS
export const ObtainNotifications = create(NOTIFICATIONS);
export const ObtainNotificationsLog = create(NOTIFICATIONS_LOG);
export const EditNotifications = create(EDIT_NOTIFICATIONS);
export const ModifyNotifications = create(MODIFY_NOTIFICATIONS);
export const SaveNotifications = create(SAVE_NOTIFICATIONS);

//PHYSICAL_PERSON
export const ObtainPhysicalPerson = create(PHYSICAL_PERSON);
export const EditPhysicalPerson = create(EDIT_PHYSICAL_PERSON);
export const ADDPhysicalPerson = create(ADD_PHYSICAL_PERSON);
export const ModifyPhysicalPerson = create(MODIFY_PHYSICAL_PERSON);
export const CreateCase = create(CREATE_CASE);

//LEGAL_PERSON
export const ObtainLegalPerson = create(LEGAL_PERSON);
export const EditLegalPerson = create(EDIT_LEGAL_PERSON);
export const ADDLegalPerson = create(ADD_LEGAL_PERSON);
export const ModifyLegalPerson = create(MODIFY_LEGAL_PERSON);

//DOCUMENTACIÓN
export const ListaDeDocumentos = create(LISTA_DE_DOCUMENTOS);
export const AgregarDocumento = create(AGREGAR_DOCUMENTO);
export const EditarDocumento = create(EDITAR_DOCUMENTO);
export const ModificarDocumento = create(MODIFICAR_DOCUMENTO);
export const LimpiarDocumentos = create(LIMPIAR_DOCUMENTOS);

//CLIENTES/VENDEDOR
export const ListaDeClientes = create(LISTA_DE_CLIENTES);
export const AgregarCliente = create(AGREGAR_CLIENTE);
export const EditarCliente = create(EDITAR_CLIENTE);
export const LimpiarCliente = create(LIMPIAR_CLIENTE);
