import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { CreateCase, MostrarMensaje } from "../actions";
import { CREATE_CASE } from "../actionTypes";
import { createCase } from "../api";
import { Resource } from "ghost-stories/dist/unions/Resource";

const crearCasos = (action$) =>
  action$.pipe(
    ofType(CREATE_CASE),
    toParams,
    makeRequest(createCase),
    map(CreateCase)
  );

const onSave = (action$) =>
  action$.pipe(
    ofType(CREATE_CASE),
    filter((action) => Resource.isQuery(action.payload)),
    mergeMap(() => [
      MostrarMensaje({
        open: true,
        mensaje: "Datos enviados con éxito",
        tipo: "success",
      }),
    ])
  );

export default combineEpics(crearCasos, onSave);
