import { merge } from "remeda";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  ADD_LEGAL_PERSON,
  EDIT_LEGAL_PERSON,
  LEGAL_PERSON,
} from "../actionTypes";

const initial = {
  list: Resource.Empty({ ClientID: "" }),
  form: {
    ClientID: "",
    ClientBusinessName: "",
    ClientTaxpayerRegistration: "",
    ClientConstitutionDate: new Date(),
    ClientHome: "",
    ClientPhoneNumber: "",
    ClientMail: "",
    ClientLegalStructure: "",
    ClientLegalStructureOtherSpecify: "",
    ClientHeading: "",
    ClientHeadingOtherSpecify: "",
    ClientLegalRepresentativeNameAndLastname: "",
    ClientLegalRepresentativeCI: "",
    ClientLegalRepresentativePosition: "",
    ClientAcceptsTruthOfTheInformation: "",
    ClientSwornDeclaration: "",
    ClientThirdPartyIncomeName: "",
    ClientThirdPartyIncomeCI: "",
    ClientThirdPartyIncomeRelationship: "",
    ClientOtherSpecify: "",
    ClientHeIsAShareholder: "",
    ClientHeIsAShareholderName: "",
    ClientHeIsAShareholderInstitutionName: "",
    ClientHeIsAShareholderPosition: "",
    ClientHeIsAShareholderStartDate: new Date(),
    ClientHeIsAShareholderEndDate: new Date(),
    ClientIsARelativeOfAShareholderPartnerName: "",
    ClientIsARelativeOfAShareholderrRelativesName: "",
    ClientIsARelativeOfAShareholderDegreeOfKinship: "",
    ClientIsARelativeOfAShareholderInstitutionName: "",
    ClientIsARelativeOfAShareholderPosition: "",
    ClientIsARelativeOfAShareholderStartDate: new Date(),
    ClientIsARelativeOfAShareholderEndDate: new Date(),
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case LEGAL_PERSON:
      return merge(state, { list: action.payload });
    case ADD_LEGAL_PERSON:
      return merge(state, { form: action.payload });
    case EDIT_LEGAL_PERSON:
      return merge(state, { form: action.payload });
    default:
      return state;
  }
};
