/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client }) => {
  return (
    <div>
      <div className="w-100 p-3">
        <Button
          color="secondary"
          className="btn-gradient text-white bg-tarjeta text-center px-4 py-5 w-100 rounded-lg"
          href={"https://wa.me/" + value.data.AdviserNumber}
          target="_blank"
        >
          <div>
            <a
              className="font-weight-bold font-size-lg"
              title="Solicitar nuevo contrato"
            >
              Solicitar nuevo contrato
            </a>
          </div>
        </Button>
      </div>
    </div>
  );
};
