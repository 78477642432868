import React, { useEffect, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Hidden,
  IconButton,
  Box,
  Popover,
  Divider,
  Tooltip,
} from "@material-ui/core";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import { useDispatch } from "react-redux";
import {
  ModifyNotifications,
  SaveNotifications,
  ObtainNotificationsLog,
} from "../../../../redux/actions";
import { FIX_URL } from "ghost-stories/dist/constants";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  FIND_NOTIFICATIONS_LOG_CODE,
  POST_NOTIFICATIONS_CODE,
  PUT_NOTIFICATIONS_CODE,
} from "../../../../constants/codes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authentication from "@kdpw/msal-b2c-react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { value, notifications } = props;
  const dispatch = useDispatch();
  const id_token = authentication.getIdToken();
  const id = id_token.extension_CI;
  const cod = "?code=" + POST_NOTIFICATIONS_CODE;
  const codNotifications = "?code=" + PUT_NOTIFICATIONS_CODE;
  const codFindNotifications = "?code=" + FIND_NOTIFICATIONS_LOG_CODE;
  const filteredMessages = notifications
    // eslint-disable-next-line array-callback-return
    .filter((x) => {
      if (!value.data.filter((y) => y.MsgID === x.MsgID).length) {
        return x;
      }
    })
    .concat(
      // eslint-disable-next-line array-callback-return
      value.data.filter((x) => {
        if (!notifications.filter((y) => y.MsgID === x.MsgID).length) {
          return x;
        }
      })
    );
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (filteredMessages.length === 0) {
      return;
    }
    dispatch(
      SaveNotifications(Resource.Query({ [FIX_URL]: cod, filteredMessages }))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const unReaded = value.data.filter((x) => x.MsgIsReaded === "N");
  const isUnReaded = () => {
    if (unReaded.length <= 0) {
      return false;
    }
    if (unReaded.length > 0) {
      return true;
    }
  };

  const msgReadesIcon = (state) => {
    if (state === "N") {
      return true;
    }
    return false;
  };
  const color = (state) => {
    if (state === "N") {
      return "#ED601C";
    }
    return "";
  };

  const onRead = useCallback(
    (identifier) => {
      const readedState = {
        MsgIsReaded: "S",
      };
      dispatch(
        ModifyNotifications(
          Resource.Query({
            [FIX_URL]: identifier + codNotifications,
            ...readedState,
          })
        )
      );
      setTimeout(() => {
        dispatch(
          ObtainNotificationsLog(
            Resource.Query({ [FIX_URL]: codFindNotifications, CustomerID: id })
          )
        );
      }, 1000);
    },
    [dispatch, codNotifications, codFindNotifications, id]
  );
  return (
    <div className="d-flex align-items-left popover-header-wrapper">
      <Hidden smDown>
        <Box component="span">
          <IconButton
            onClick={handleClick1}
            color="inherit"
            className="btn-inverse mx-1 d-50"
          >
            {isUnReaded() ? (
              <div className="badge badge-pill badge-primary badge-header">
                {unReaded.length}
              </div>
            ) : (
              ""
            )}
            <NotificationsActiveTwoToneIcon />
          </IconButton>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              paper: "app-header-dots",
            }}
          >
            <div className="popover-custom-xl overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                <div className="bg-composed-wrapper--content text-light px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notificaciones
                  </h4>
                </div>
              </div>
              <div className="height-280">
                <PerfectScrollbar>
                  {value.data
                    .map((x) => (
                      <div
                        key={x.MsgID}
                        className="timeline-list timeline-list-offset timeline-list-offset-dot"
                      >
                        <div className="timeline-item">
                          <div className="timeline-item-offset">
                            {msgReadesIcon(x.MsgIsReaded) ? (
                              <Tooltip arrow title="Marcar como leído">
                                <IconButton
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => onRead(x.MsgID)}
                                >
                                  <FontAwesomeIcon
                                    icon={["fa", "check-circle"]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="timeline-item--content flex-end">
                            <div className="timeline-item--icon"></div>
                            <h4
                              className="timeline-item--label mb-2 font-weight-bold"
                              style={{
                                color: color(x.MsgIsReaded),
                              }}
                            >
                              {x.MsgTitle}
                            </h4>
                            <div className="font-size-sm text-dark">
                              <Tooltip arrow title="Fecha de envío">
                                <span>
                                  <FontAwesomeIcon
                                    icon={["far", "calendar-alt"]}
                                    className="mr-1"
                                  />
                                </span>
                              </Tooltip>
                              {x.MsgDate}
                              <Tooltip arrow title="Hora de envío">
                                <span>
                                  <FontAwesomeIcon
                                    icon={["far", "clock"]}
                                    className="ml-2 mr-1"
                                  />
                                </span>
                              </Tooltip>
                              {x.MsgTime}
                            </div>
                            <p className="mr-4">{x.MsgText}</p>
                          </div>
                        </div>
                      </div>
                    ))
                    .reverse()}
                </PerfectScrollbar>
              </div>
              <Divider />
              <div className="text-center py-3"></div>
            </div>
          </Popover>
        </Box>
      </Hidden>
    </div>
  );
};
