import React from "react";
import { Card } from "@material-ui/core";
import avatar8 from "../../assets/images/avatars/avatar8.png";
import authentication from "@kdpw/msal-b2c-react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const id_token = authentication.getIdToken();
  const Name = id_token.given_name;
  const LastName = id_token.family_name;
  return (
    <Card className="card-box mb-3 p-4">
      <div className="d-flex align-items-center mb-3">
        <div className="avatar-icon-wrapper rounded-circle mr-3">
          <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
            <div className="rounded-circle overflow-hidden">
              <img alt="..." className="img-fluid p-2" src={avatar8} />
            </div>
          </div>
        </div>
        <div className="w-100">
          <h1
            className="display-3 mb-2 font-weight-bold color-secondary"
            style={{ color: "#ED601C" }}
          >
            Bienvenido{" "}
            <b style={{ color: "#003735" }}>
              {decodeURIComponent(escape(Name))}{" "}
              {decodeURIComponent(escape(LastName))}
            </b>
          </h1>
          <span className="text-black-50 d-block" style={{ fontSize: "130%" }}>
            Aqui podras administrar tus clientes
          </span>
        </div>
      </div>
    </Card>
  );
};
