import React, { Fragment } from "react";
import clsx from "clsx";
import { Box, Button, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import authentication from "@kdpw/msal-b2c-react";
import avatar from "../../../../assets/images/avatars/avatar.png";
import UserBoxUser from "./UserBoxUser";

const SidebarUserbox = (props) => {
  const id_token = authentication.getIdToken();
  const Role = id_token.idp;

  const ro = () => {
    if (Role !== undefined) {
      return "ADMINISTRATOR";
    } else {
      return "USER";
    }
  };
  const valid = ro() === "ADMINISTRATOR";
  const { sidebarToggle, sidebarHover } = props;

  const logout = () => {
    authentication.signOut();
  };

  const Name = id_token.given_name;
  const LastName = id_token.family_name;

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover,
        })}
      >
        {!valid ? (
          <UserBoxUser />
        ) : (
          <Avatar
            alt="Remy Sharp"
            src={avatar}
            className="app-sidebar-userbox-avatar"
          />
        )}
        <Box className="app-sidebar-userbox-name">
          <Box className="mb-2">
            <b>
              {decodeURIComponent(escape(Name))}{" "}
              {decodeURIComponent(escape(LastName))}
            </b>
          </Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={logout}
            >
              Cerrar sesión
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserbox);
