import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import DatosParientesTable from "./components/DatosParientesTable";
import SocioAccionistaTable from "./components/SocioAccionistaTable";
import AltaGerenciaTable from "./components/AltaGerenciaTable";
import { useDispatch } from "react-redux";
import { EditPhysicalPerson } from "../../../../../../redux/actions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import {
  altaGerenciaInit,
  cargoPublicoInit,
  conyugeInit,
  personaCercanaInit,
  socioAccionistaInit,
} from "../../../../../../constants/helpers";

const DatosDeOcupacion = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const [cargo, setCargo] = useState(form.ClientPublicOffice);
  const [conyuges, setConyuges] = useState(form.ClientIsSpouse);
  const [perCercana, setPerCercana] = useState(
    form.ClientPublicPositionPartner
  );
  const [socAccionista, setSocAccionista] = useState(
    form.ClientShareholderPartner
  );
  const [altGerencia, setAltGerencia] = useState(
    form.ClientSeniorManagementMember
  );

  const onChangeBoxCargo = (i) => (e) => {
    let newArr = [...cargoPublicoInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setCargo(newArr);
  };

  const onChangeBoxConyuge = (i) => (e) => {
    let newArr = [...conyugeInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setConyuges(newArr);
  };

  const onChangeBoxPerCercana = (i) => (e) => {
    let newArr = [...personaCercanaInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setPerCercana(newArr);
  };

  const onChangeBoxSocioAccionista = (i) => (e) => {
    let newArr = [...socioAccionistaInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setSocAccionista(newArr);
  };

  const onChangeBoxAltaGerencia = (i) => (e) => {
    let newArr = [...altaGerenciaInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setAltGerencia(newArr);
  };

  const handleClientStartInOffice = useCallback(
    (date) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          ClientStartInOffice: date.toString(),
        })
      );
    },
    [form, dispatch]
  );

  const handleClientEndInOffice = useCallback(
    (date) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          ClientEndInOffice: date.toString(),
        })
      );
    },
    [form, dispatch]
  );

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        ClientPublicOffice: cargo,
        ClientIsSpouse: conyuges,
        ClientPublicPositionPartner: perCercana,
        ClientShareholderPartner: socAccionista,
        ClientSeniorManagementMember: altGerencia,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargo, conyuges, perCercana, socAccionista, altGerencia]);

  return (
    <>
      <form className="mt-3">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <div className="font-size-lg ">
              ¿Ocupa o ha ocupado un cargo público?
            </div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {cargo.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxCargo(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          {cargo[0].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  label="Nombre de la entidad o institución"
                  variant="outlined"
                  value={form.ClientEntityName}
                  name="ClientEntityName"
                  onChange={onChangeField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  label="Cargo público ocupado"
                  variant="outlined"
                  value={form.ClientHeldPosition}
                  name="ClientHeldPosition"
                  onChange={onChangeField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={5}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd-MM-yyyy"
                    label="Fecha de inicio en el cargo"
                    value={form.ClientStartInOffice}
                    onChange={(date) => handleClientStartInOffice(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={5}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd-MM-yyyy"
                    label="Fecha de cese en el cargo"
                    value={form.ClientEndInOffice}
                    onChange={(date) => handleClientEndInOffice(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <div className="font-size-lg ">
              ¿Es usted cónyuge, concubino/a o pariente por consanguinidad o
              afinidad en línea ascendente, descendente o colateral de una
              persona que ocupa o haya ocupado un cargo público?
            </div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {conyuges.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxConyuge(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <div className="font-size-lg ">
              ¿Es una persona asociada cercana de una persona que haya ocupado
              un cargo público?
            </div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {perCercana.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxPerCercana(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          {conyuges[0].state === true || perCercana[0].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <DatosParientesTable form={form} />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <div className="font-size-lg ">
              ¿Es usted un/a socio/a, accionista, asociado/a, administrador/a,
              representante legal, apoderado/a o mandatario/a con poder de
              disposición, de una persona o ente jurídico donde una persona que
              ocupe o haya ocupado un cargo público tenga el 10% o más de
              capital social, aporte o participación?
            </div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {socAccionista.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxSocioAccionista(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          {socAccionista[0].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <SocioAccionistaTable form={form} />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <div className="font-size-lg ">
              ¿Es usted miembro de la alta gerencia, director/a, miembro de la
              Junta, o tiene una función prominente en alguna organización
              internacional o intergubernamental?
            </div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {altGerencia.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxAltaGerencia(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          {altGerencia[0].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <AltaGerenciaTable form={form} />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}
        </Grid>
      </form>
    </>
  );
};

export default DatosDeOcupacion;
