// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT: "/root",
  SIGN_UP: "/signup",
  LOG_IN: "/login",
  LANDING: "/landing",
  E404: "/error404",
  DASHBOARD: "/",
  ESTADO_GENERAL: "/estado_general",
  APORTES: "/aportes",
  DETALLE_CONTRATO: "/detalle_contrato",
  AMIGOS: "/amigos",
  EDIFICIOS: "/edificios",
  EDIFICIOS_AGREGAR: "/edificios/agregar",
  EDIFICIOS_EDITAR: "/edificios/editar/:id",
  ASESORES: "/asesores",
  ASESORES_AGREGAR: "/asesores/agregar",
  ASESORES_EDITAR: "/asesores/editar/:id",
  ATC: "/atc",
  ATC_AGREGAR: "/atc/agregar",
  ATC_EDITAR: "/atc/editar/:id",
  ACTUALIZACION_DATOS: "/datos/editar",
  GESTION_DE_DOCUMENTOS: "/gestion_documentos",
  AGREGAR_DOCUMENTACION: "/agregar_documentacion",
  EDITAR_DOCUMENTO: "/editar/:id",
  NUEVOS_CLIENTES: "/clientes",
  AGREGAR_CLIENTE: "/agregar_cliente",
  DOCUMENTOS: "/documentos",
};
