import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ObtainNotificationsLog } from "../../../../redux/actions";
import { OnQuery, OnError, OnEmpty } from "../../../../pages/ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import NotificationsData from "./NotificationsData";
import { FIND_NOTIFICATIONS_LOG_CODE } from "../../../../constants/codes";
import { FIX_URL } from "ghost-stories/dist/constants";
import authentication from "@kdpw/msal-b2c-react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { value } = props;
  const dispatch = useDispatch();
  const id_token = authentication.getIdToken();
  const id = id_token.extension_CI;
  const cod = "?code=" + FIND_NOTIFICATIONS_LOG_CODE;
  const notificationsLog = useSelector((state) => state.notifications.log);

  useEffect(() => {
    dispatch(
      ObtainNotificationsLog(
        notificationsLog.update({ [FIX_URL]: cod, CustomerID: id })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ResourceRender
        resource={notificationsLog}
        Data={NotificationsData}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
        props={{ notifications: value }}
      />
    </Fragment>
  );
};
