import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { EditPhysicalPerson } from "../../../../../../../redux/actions";

const DatosParientesTable = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const [data, setData] = React.useState(
    form.ClientSeniorManagementMemberTable
  );
  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        ClientSeniorManagementMemberTable: data,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <MaterialTable
        className="mb-3"
        title=""
        columns={[
          {
            title: "Denominación de la Organización",
            field: "denOrganizacion",
          },
          {
            title: "No Documento RUC",
            field: "documento",
          },
          { title: "Nombres y Apellidos", field: "nombreApellido" },
          { title: "No de Documento", field: "numeroDocumento" },
        ]}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                const current = data.concat([newData]);
                setData(current);
                resolve();
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                if (oldData) {
                  const current = data.map((x, i) =>
                    i === data.indexOf(oldData) ? newData : x
                  );
                  setData(current);
                }
                resolve();
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                const current = data.filter(
                  (x, i) => i !== data.indexOf(oldData)
                );
                setData(current);
                resolve();
              }, 600);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "filas",
            firstTooltip: "Primera página",
            lastTooltip: "Ultima página",
            previousTooltip: "Anterior",
            nextTooltip: "Siguiente",
          },
          toolbar: {
            nRowsSelected: "{0} file(s) seleccionada",
            searchPlaceholder: "Buscar",
            searchTooltip: "Buscar",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            emptyDataSourceMessage: "No se cargaron datos",
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",
            editRow: {
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
              deleteText: "¿Está seguro que desea eliminar esta fila?",
            },
          },
        }}
      />
    </>
  );
};

export default DatosParientesTable;
