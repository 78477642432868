import React, { useState } from "react";
import { Dialog, Tooltip, IconButton, Card } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { edificio } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal((shown) => !shown);

  const valid = () => {
    if (edificio.BuildVisibility === "S") {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Tooltip arrow title="Ver más">
        <IconButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={toggle}
        >
          <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={modal} onClose={toggle}>
        <Card className="card-box p-4">
          <div className="d-flex align-items-center flex-column flex-sm-row">
            <div>
              <div className="mt-1">
                <FontAwesomeIcon
                  icon={["far", "building"]}
                  className="font-size-xxl text-second"
                />
              </div>
            </div>
            <div className="pl-0 pl-sm-4">
              <div className="d-block text-center d-sm-flex align-items-center">
                <span className="font-size-lg">{edificio.BuildName}</span>
                <span className="text-info ml-2 badge badge-neutral-info">
                  {valid() ? "Activo" : "Incativo"}
                </span>
              </div>
              <p className="text-black-50 mb-0">{edificio.BuildDate}</p>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  );
};
