import React, { useState } from "react";
import { TablePagination } from "@material-ui/core";
import Fila from "./Fila";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover text-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-left">Asesor/a</th>
            <th className="text-left">Número de contacto</th>
            <th className="text-left">Correo</th>
          </tr>
        </thead>

        <tbody>
          {value.data
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((adviser) => (
              <Fila adviser={adviser} key={adviser.AdviserID} />
            ))}
        </tbody>
      </table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={value.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
