import React, { useEffect, useState } from "react";
import authentication from "@kdpw/msal-b2c-react";
import DashboardAdmin from "./DashboardAdmin";
import DashboardUser from "./DashboardUser";
import DashboardVendedor from "./DashboardVendedor";
import DashboardBackOffice from "./DashboardBackOffice";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const id_token = authentication.getIdToken();
  const Role = id_token.idp;
  const [response, setResponse] = useState("");

  const getToken = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + `${id_token.idp_access_token}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => setResponse(JSON.parse(result).value[0].displayName))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (Role !== undefined) {
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ro = () => {
    if (response === "admin_micrositio") {
      return "ADMINISTRADOR";
    }
    if (response === "vendedor_micrositio") {
      return "VENDEDOR";
    }
    if (response === "Back Office") {
      return "BACKOFFICE";
    }
    return "USER";
  };

  return (
    <>
      {ro() === "USER" ? <DashboardUser /> : null}
      {ro() === "ADMINISTRADOR" ? <DashboardAdmin /> : null}
      {ro() === "VENDEDOR" ? <DashboardVendedor /> : null}
      {ro() === "BACKOFFICE" ? <DashboardBackOffice /> : null}
    </>
  );
};
