import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent } from "@material-ui/core";
import Slider from "react-slick";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value }) => {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 940,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const buildsVisible = value.data.filter((x) => x.BuildVisibility === "S");
  return (
    <div>
      <div className="bg-second pt-4 pb-2 px-4 mb-4 rounded">
        <Slider {...settings} className="w-100 p-2">
          {buildsVisible.map((x) => (
            <div className="p-3" key={x.BuildID}>
              <a
                target="blank"
                href={"https://" + x.BuildURL}
                className="mb-3 card card-box card-box-border-bottom border-primary card-box-hover-rise-alt"
              >
                <CardContent className="p-3">
                  <div className="align-box-row">
                    <div className="text-left">
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={["far", "building"]}
                          className="font-size-xxl text-second"
                        />
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg text-black pr-1">
                          {x.BuildDate}
                        </b>
                        <span className="text-black-50">{x.BuildName}</span>
                      </div>
                    </div>
                    <div className="ml-auto card-hover-indicator"></div>
                  </div>
                </CardContent>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
