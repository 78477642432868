import { merge } from "remeda";
import { CLIENTS } from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({ nrodocumento: "", email: "" }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case CLIENTS:
      return merge(state, { list: action.payload });
    default:
      return state;
  }
};
