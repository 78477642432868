import React from "react";
import { Button, Avatar } from "@material-ui/core";
import clsx from "clsx";
import avatar from "../../../../assets/images/avatars/avatar.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const Icon = () => {
  const classes = useStyles();
  return <Avatar alt="Remy Sharp" src={avatar} className={classes.small} />;
};

export default function SidebarFooter() {
  let paddingLeft = 14;
  const style = {
    paddingLeft,
  };
  return (
    <div>
      <Button
        color="primary"
        className={clsx("app-sidebar-button")}
        style={style}
        href="https://www.fortalezafideliza.com/signin"
      >
        {<Icon className="app-sidebar-icon" />}
        <span className="ml-3" style={{ color: "#ED601C" }}>
          Fortaleza Fideliza
        </span>
      </Button>
    </div>
  );
}
