import React, { useEffect } from "react";
import LogicaAgregar from "./LogicaAgregar";
import ResourceRender from "ghost-stories/dist/react/resource";
import { useDispatch, useSelector } from "react-redux";
import { ListaDeDocumentos } from "../../../redux/actions";
import { CODIGO_LISTA_DOCUMENTACION } from "../../../constants/codes";
import { OnQuery, OnError, OnEmpty } from "../../../pages/ResourceHelpers";

const Agregar = () => {
  const dispatch = useDispatch();
  const documentos = useSelector((state) => state.documentos.lista);
  useEffect(() => {
    dispatch(
      ListaDeDocumentos(documentos.update({ code: CODIGO_LISTA_DOCUMENTACION }))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ResourceRender
        resource={documentos}
        Data={LogicaAgregar}
        Query={OnQuery}
        Empty={OnError}
        Error={OnEmpty}
      />
    </>
  );
};

export default Agregar;
