import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { Grid } from "@material-ui/core";
import svgImage8 from "../assets/images/illustrations/505.svg";
import authentication from "@kdpw/msal-b2c-react";

export function PrivateRoute({ component: Component, roles, client, ...rest }) {
  const id_token = authentication.getIdToken();
  const Role = id_token.idp;
  const ro = () => {
    if (Role !== undefined) {
      return "ADMINISTRATOR";
    }
    if (Role === "BACKOFFICE") {
      return "BACKOFFICE";
    }
    return "USER";
  };

  const valid = roles.some((rol) => rol === ro() || rol === "BACKOFFICE");

  const ValidComponent = valid
    ? Component
    : () => (
        <Fragment>
          <div className="app-wrapper bg-secondary">
            <div className="app-main">
              <div className="app-content p-0">
                <div className="app-inner-content-layout--main">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--content">
                      <div className="hero-wrapper bg-composed-wrapper min-vh-50">
                        <div className="flex-grow-1 w-50 d-flex align-items-center">
                          <Grid
                            item
                            lg={6}
                            md={9}
                            className="px-4 mx-auto text-center text-black"
                          >
                            <img
                              src={svgImage8}
                              className="w-50 mx-auto d-block my-5 img-fluid"
                              alt="..."
                            />

                            <h1 className="display-1 mb-3 px-4 font-weight-bold">
                              No autorizado
                            </h1>
                            <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                              No tiene los permisos necesarios para ver esta
                              sección
                            </h3>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
  return <Route {...rest} render={(props) => <ValidComponent {...props} />} />;
}
