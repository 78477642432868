import React from "react";
import { Card } from "@material-ui/core";
import authentication from "@kdpw/msal-b2c-react";
import Advisers from "./Advisers";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client, advisers, atcs }) => {
  const id_token = authentication.getIdToken();
  const Name = id_token.given_name;
  const LastName = id_token.family_name;
  const contract = value
    .map((x) => x)
    .filter((xs) => xs.CustomerID === client.CustomerID);
  const contractsText = () => {
    if (contract.length <= 1) {
      return "contrato";
    }
    return "contratos";
  };
  return (
    <Card className="card-box p-4 mb-4" style={{ backgroundColor: "#ffffff" }}>
      <div className="pr-5">
        <div className="w-100">
          <h1
            className="display-3 mb-3 font-weight-bold"
            style={{ color: "#ED601C" }}
          >
            Hola{" "}
            <b style={{ color: "#003735" }}>
              {decodeURIComponent(escape(Name))}{" "}
              {decodeURIComponent(escape(LastName))}
            </b>
          </h1>
          <span className="text-black-50 d-block" style={{ fontSize: "110%" }}>
            Estás invirtiendo en tu futuro con{" "}
            <b style={{ color: "#ED601C", fontSize: "110%" }}>
              {contract.length}
            </b>{" "}
            {contractsText()}, ¡excelente decisión!.
          </span>
          <span className="text-black-50 d-block" style={{ fontSize: "110%" }}>
            Aquí podés ver el estado general de cada uno de ellos.
          </span>
        </div>
        <ResourceRender
          resource={advisers}
          Data={Advisers}
          Query={OnQuery}
          Empty={OnEmpty}
          Error={OnError}
          props={{ client: client, atcs: atcs }}
        />
      </div>
    </Card>
  );
};
