import React, { useState } from "react";
import { TablePagination } from "@material-ui/core";
import Filas from "./Filas";

const TablaDeDocumentaciones = ({ value }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover text-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-left">Codigo</th>
            <th className="text-left">Nombre</th>
            <th className="text-left">Estado</th>
            <th className="text-center" colSpan="1">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {value.data
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((docu) => (
              <Filas docu={docu} key={docu.DocumentID} />
            ))}
        </tbody>
      </table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={value.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};

export default TablaDeDocumentaciones;
