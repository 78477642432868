import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { CONTRACTS, CONTRACT_DETAIL } from "../actionTypes";
import { captureContractDetail, captureContracts } from "../api";
import { ObtainContractDetail, ObtainContracts } from "../actions";
import { addAuth } from "../api/streams";

const listContracts = (action$, state$) =>
  action$.pipe(
    ofType(CONTRACTS),
    toParams,
    addAuth(state$),
    makeRequest(captureContracts),
    map(ObtainContracts)
  );

const contractDetail = (action$, state$) =>
  action$.pipe(
    ofType(CONTRACT_DETAIL),
    toParams,
    addAuth(state$),
    makeRequest(captureContractDetail),
    map(ObtainContractDetail)
  );
export default combineEpics(listContracts, contractDetail);
