/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Dialog, Card, Divider, Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AvatarF from "../../../../assets/images/avatars/avatar.png";
import { LinearProgress } from "@material-ui/core";

const Dialogo = (props) => {
  const { cliente, docSubidos, porcentaje } = props;
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  // const [open, setOpen] = React.useState(false);
  // const [open1, setOpen1] = React.useState(false);

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const handleClose1 = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen1(false);
  // };

  return (
    <div>
      <Tooltip arrow title="Ver documentos">
        <IconButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={toggle1}
        >
          <FontAwesomeIcon icon={["fa", "eye"]} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={modal1} onClose={toggle1}>
        <Card className="card-box p-4">
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100">
                <a className="font-weight-bold font-size-lg" title="...">
                  Documentos subidos
                </a>
                <span className="text-black-50 d-block">
                  {cliente.ClientName} {cliente.ClientLastName} -{" "}
                  {Intl.NumberFormat().format(cliente.ClientCI)}
                </span>
                <span className="text-black-50 d-block">
                  {porcentaje.toFixed() === "100" ? (
                    <div className="mt-2">
                      <div className="d-flex mb-1 font-weight-bold justify-content-between font-size-sm">
                        <div className="text-uppercase opacity-6"></div>
                        <div>
                          {porcentaje.toFixed()}% Suba de archivos completa!
                        </div>
                      </div>
                      <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={porcentaje.toFixed()}
                      />
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div className="d-flex mb-1 font-weight-bold justify-content-between font-size-sm">
                        <div className="text-uppercase opacity-6"></div>
                        <div>{porcentaje.toFixed()}% De archivos subidos</div>
                      </div>
                      <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={porcentaje.toFixed()}
                      />
                    </div>
                  )}
                </span>
              </div>
            </div>
            <Divider />
            {docSubidos.length === 0 ? (
              <div className="mt-2">
                <Alert severity="warning">
                  Aún no se subió ningún documento
                </Alert>
              </div>
            ) : (
              docSubidos.map((x) => (
                <div className="my-2 font-size-sm p-3 bg-secondary rounded-sm">
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">{x.titulo}:</span>
                    <a className="text-black-50" href={x.fileURL}>
                      {x.fileURL.slice(-4) === ".png" ||
                      x.fileURL.slice(-4) === ".jpg" ||
                      x.fileURL.slice(-4) === "jpeg" ? (
                        <Avatar alt="D" src={x.fileURL} className="mr-2" />
                      ) : null}

                      {x.fileURL.slice(-4) === ".xls" ||
                      x.fileURL.slice(-4) === "xlsx" ? (
                        <div className="nav-link-icon opacity-6">
                          <FontAwesomeIcon
                            icon={["far", "dot-circle"]}
                            className="font-size-sm text-success mr-3"
                          />
                        </div>
                      ) : null}

                      {x.fileURL.slice(-4) === "docx" ? (
                        <div className="nav-link-icon opacity-6">
                          <FontAwesomeIcon
                            icon={["far", "file-word"]}
                            className="font-size-xl text-info mr-3"
                          />
                        </div>
                      ) : null}

                      {x.fileURL.slice(-4) === ".pdf" ? (
                        <div className="nav-link-icon opacity-6">
                          <FontAwesomeIcon
                            icon={["far", "file-pdf"]}
                            className="font-size-xl text-danger mr-3"
                          />
                        </div>
                      ) : null}

                      {x.fileURL.slice(-4) === ".pdf" ||
                      x.fileURL.slice(-4) === "docx" ||
                      x.fileURL.slice(-4) === ".xls" ||
                      x.fileURL.slice(-4) === "xlsx" ||
                      x.fileURL.slice(-4) === ".png" ||
                      x.fileURL.slice(-4) === ".jpg" ||
                      x.fileURL.slice(-4) === "jpeg" ? null : (
                        <Avatar alt="D" src={AvatarF} className="mr-2" />
                      )}
                    </a>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card>
      </Dialog>
    </div>
  );
};

export default Dialogo;
