import { merge } from "remeda";
import { OBTAIN_TOKEN } from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({}),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case OBTAIN_TOKEN:
      return merge(state, { list: action.payload });
    default:
      return state;
  }
};
