import React from "react";
import Tabla from "./Tabla";

const NuevosClientes = () => {
  return (
    <>
      <Tabla />
    </>
  );
};

export default NuevosClientes;
