import { merge } from "remeda";
import { MOSTRAR_MENSAJE } from "../actionTypes";

const initial = {
  mensaje: "",
  tipo: "",
  open: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case MOSTRAR_MENSAJE:
      return merge(state, action.payload);
    default:
      return state;
  }
};
