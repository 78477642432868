import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../src/assets/base.scss";
import "./fontAwesomeSetup";
import { useDispatch, useSelector } from "react-redux";
import { ObtainToken } from "./redux/actions";
import { LoadingInit } from "./utils/LoadingInit";

var cont = 0;
const App = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.tokens.list);
  useEffect(() => {
    dispatch(ObtainToken(token.update({})));
    cont = cont + 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      cont = 2;
      dispatch(ObtainToken(token.update({})));
    }, 270000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isToken = () => {
    if (token.value === undefined && cont > 1) {
      return true;
    }
    if (token.value === undefined && cont <= 1) {
      return false;
    }

    return true;
  };
  return (
    <BrowserRouter>
      <CssBaseline />
      <ScrollToTop>{isToken() ? <Routes /> : <LoadingInit />}</ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
