import React, { useState } from "react";
import { TablePagination } from "@material-ui/core";
import Fila from "./Fila";

const NuevosClientesTable = (props) => {
  const { value, filtroNombre, filtroCI } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const clientesFiltered = () => {
    if (filtroNombre === "" && filtroCI === "") {
      return value.data;
    } else {
      if (filtroNombre !== "") {
        const filtrado = value.data.filter((x) =>
          x.ClientName.toLowerCase().includes(filtroNombre.toLowerCase())
        );
        return filtrado;
      }
      if (filtroCI !== "") {
        const filtrado = value.data.filter((x) =>
          x.ClientCI.includes(filtroCI)
        );
        return filtrado;
      }
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover text-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-left">Nombre/Apellido</th>
            <th className="text-left">CI/Estado</th>
            <th className="text-left">Acciones</th>
          </tr>
        </thead>

        <tbody>
          {clientesFiltered()
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((cliente) => (
              <Fila cliente={cliente} key={cliente.ClientID} />
            ))}
        </tbody>
      </table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={clientesFiltered().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

export default NuevosClientesTable;
