import React, { useCallback, useState } from "react";
import Formulario from "../Formulario";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_AGREGAR_DOCUMENTO } from "../../../constants/codes";
import { useHistory } from "react-router-dom";
import { AgregarDocumento, EditarDocumento } from "../../../redux/actions";
import { FIX_URL } from "ghost-stories/dist/constants";
import { Resource } from "ghost-stories/dist/unions/Resource";

const LogicaAgregar = () => {
  const dispatch = useDispatch();
  const cod = "?code=" + CODIGO_AGREGAR_DOCUMENTO;
  const form = useSelector((state) => state.documentos.form);
  const lista = useSelector((state) => state.documentos.lista);
  const IDS = lista.value.data.map((x) => x.DocumentID);
  const history = useHistory();
  const [valid, setValid] = useState(false);

  const onCancelar = () => {
    history.goBack();
  };

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditarDocumento({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChangeVisibility = useCallback(
    (event) => {
      const estado = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditarDocumento({ ...form, DocumentState: estado() }));
    },
    [form, dispatch]
  );

  const onGuardar = useCallback(
    (event) => {
      event.preventDefault();
      if (IDS.includes(form.DocumentID)) {
        setValid(true);
      } else {
        setValid(false);
        dispatch(AgregarDocumento(Resource.Query({ [FIX_URL]: cod, ...form })));
        setTimeout(history.goBack, 200);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, form, history, cod]
  );
  return (
    <>
      <Formulario
        {...{
          title: "Agregar Documento",
          onCancelar,
          form,
          onChangeField,
          onChangeVisibility,
          onGuardar,
          valid,
          botonIDReadOnly: false,
        }}
      />
    </>
  );
};

export default LogicaAgregar;
