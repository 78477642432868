import React, { Fragment } from "react";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import ChartTipoAporte from "./ChartTipoAporte";
import { Box, Card } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, id, contracts }) => {
  const client = value.map((x) => x).find((xs) => xs.CustomerID === id);

  return (
    <Fragment>
      <Card className="mb-4">
        <div className="card-header-alt d-flex justify-content-between p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-1 text-black">
              Detalle de cuotas
            </h6>
            <p className="text-black-50 mb-0">Desglose de cuotas por tipo</p>
          </div>
          <Box className="d-flex align-items-center"></Box>
        </div>
        <div className="mx-4 divider" />
        <div className="mx-4 divider" />
        <ResourceRender
          resource={contracts}
          Data={ChartTipoAporte}
          Query={OnQuery}
          Empty={OnEmpty}
          Error={OnError}
          props={{ client: client }}
        />
        <div className="text-center mb-4"></div>
      </Card>
    </Fragment>
  );
};
