/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { LinearProgress, Button, Card } from "@material-ui/core";
import Slider from "react-slick";
import contratos from "../../../assets/images/fortaleza/CONTRATO.png";
import { ObtainContractDetail } from "../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { useDispatch } from "react-redux";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import SolicitudNuevoContrato from "./SolicitudNuevoContrato";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client, buttonText, advisers }) => {
  const dispatch = useDispatch();
  const contract = value
    .map((x) => x)
    .filter((xs) => xs.CustomerID === client.CustomerID);
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 940,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const obtenerDetalle = useCallback(
    (contract) =>
      dispatch(
        ObtainContractDetail(
          Resource.Query({
            tipoConsulta: "ContractNumber",
            nro: contract,
          })
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract]
  );

  return (
    <div className="card card-box d-block p-4">
      <Slider {...settings} className="w-100 p-2">
        {contract.map((x) => (
          <div>
            <div className="p-4">
              <Card className="px-3 pt-3 mb-2 p-3">
                <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
                  <small>{x.ContractStatus}</small>
                </span>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-icon-wrapper rounded-circle ml-2 mt-3">
                    <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                      <img
                        alt="..."
                        className="d-block img-fluid"
                        src={contratos}
                        style={{ maxWidth: "70%" }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <a className="font-weight-bold font-size-lg" title="...">
                      Monto Aportado: GS.{" "}
                      {parseInt(x.TotalPaymentsGs).toLocaleString()}
                    </a>
                    <span className="text-black-50 d-block">
                      Contrato número: {x.ContractNumber}
                    </span>
                    <div className="d-flex align-items-center pt-1">
                      <LinearProgress
                        variant="determinate"
                        className="flex-grow-1 progress-animated-alt"
                        color="primary"
                        value={8}
                      />
                      <div className="font-weight-bold text-black-50 pl-2">
                        {((x.TotalFees / x.TotalFeesContract) * 100).toFixed()}%
                      </div>
                    </div>
                    <span className="text-black-50 d-block">
                      {x.TotalFees}/{x.TotalFeesContract}
                    </span>
                  </div>
                </div>
                <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">Tipo de plan:</span>
                    <span className="text-black-50">{x.PlanType}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Edificio Adjudicado:
                    </span>
                    <span className="text-black-50">
                      {x.isOwner ? x.EdificeName : "Ninguno"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Departamento Adjudicado:
                    </span>
                    <span className="text-black-50">
                      {x.isOwner ? x.AppartmentOwned : "Ninguno"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Cant. cuotas de espera
                    </span>
                    <span className="text-black-50">
                      {x.TotalFeesTypeWaiting}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Cant. cuotas de posesión
                    </span>
                    <span className="text-black-50">
                      {x.TotalFeesTypeOwner}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Cant. cuotas extraordinarias
                    </span>
                    <span className="text-black-50">
                      {x.TotalFeesTypeExraordinary}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="font-weight-bold">
                      Cant. cuotas de posesión pura
                    </span>
                    <span className="text-black-50">
                      {x.TotalFeesTypePureOwner}
                    </span>
                  </div>
                </div>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="text-uppercase w-100 font-size-xs"
                  onClick={() => obtenerDetalle(x.ContractNumber)}
                >
                  <small className="font-weight-bold">{buttonText}</small>
                </Button>
              </Card>
            </div>
          </div>
        ))}

        <ResourceRender
          resource={advisers}
          Data={SolicitudNuevoContrato}
          Query={OnQuery}
          Empty={OnEmpty}
          Error={OnError}
          props={{ client: client }}
        />
      </Slider>
    </div>
  );
};
