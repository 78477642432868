import React from "react";
import { Grid } from "@material-ui/core";
import Chart from "react-apexcharts";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, client }) => {
  const contract = value
    .map((x) => x)
    .filter((xs) => xs.CustomerID === client.CustomerID);
  const contractsNumber = contract.map(
    (x) => "Contrato número: " + x.ContractNumber
  );
  const extraordinary = contract.map((x) => x.TotalFeesTypeExraordinary);
  const pure = contract.map((x) => x.TotalFeesTypePureOwner);
  const waiting = contract.map((x) => x.TotalFeesTypeWaiting);
  const owner = contract.map((x) => x.TotalFeesTypeOwner);
  const pending = contract.map((x) => x.TotalFeesTypePending);
  const chart5Options = {
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      stacked: true,
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    colors: ["#003735", "#007F79", "#ED601C", "#F5A200", "#CCCCCC"],
    fill: {
      opacity: 1,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    legend: {
      show: false,
    },
    labels: contractsNumber,
  };
  const chart5Data = [
    {
      name: "Posesión Pura",
      data: pure,
    },
    {
      name: "Extraordinario",
      data: extraordinary,
    },
    {
      name: "Espera",
      data: waiting,
    },
    {
      name: "Posesión",
      data: owner,
    },
    {
      name: "Pendiente",
      data: pending,
    },
  ];
  return (
    <div className="p-4">
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Chart
            options={chart5Options}
            series={chart5Data}
            type="bar"
            height={330}
          />
        </Grid>
      </Grid>
    </div>
  );
};
