import { combineEpics } from "redux-observable";
import clients from "./clients";
import contracts from "./contracts";
import adviser from "./adviser";
import atc from "./atc";
import buildings from "./buildings";
import notifications from "./notifications";
import tokens from "./tokens";
import physicalPerson from "./physicalPerson";
import documentos from "./documentos";
import clientes from "./clientes";
import legalPerson from "./legalPerson";
import caso from "./caso";

export const rootEpic = combineEpics(
  clients,
  contracts,
  adviser,
  atc,
  buildings,
  notifications,
  tokens,
  physicalPerson,
  documentos,
  clientes,
  legalPerson,
  caso
);
