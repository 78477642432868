import React, { useEffect } from "react";
import { OnQuery, OnError, OnEmpty } from "../../../../pages/ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import Dialogo from "./Dialogo";
import { ObtainClients, ObtainPhysicalPerson } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import authentication from "@kdpw/msal-b2c-react";
import { FIND_PHYSICAL_PERSON_CODE } from "../../../../constants/codes";
import { FIX_URL } from "ghost-stories/dist/constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const persona = "fisica";
  const id_token = authentication.getIdToken();
  const dispatch = useDispatch();
  const id = id_token.extension_CI;
  const email = id_token.emails[0];
  const clients = useSelector((state) => state.clients.list);
  const physicalPerson = useSelector((state) => state.physicalPerson.list);
  const legalPerson = useSelector((state) => state.legalPerson.list);

  useEffect(() => {
    dispatch(ObtainClients(clients.update({ nrodocumento: id, email: email })));
    if (persona === "fisica") {
      dispatch(
        ObtainPhysicalPerson(
          physicalPerson.update({
            [FIX_URL]: FIND_PHYSICAL_PERSON_CODE,
            ClientID: authentication.getIdToken().extension_CI,
          })
        )
      );
    }
    // else {
    //   dispatch(
    //     ObtainLegalPerson(
    //       legalPerson.update({
    //         [FIX_URL]: FIND_LEGAL_PERSON_CODE,
    //         ClientID: authentication.getIdToken().extension_CI,
    //       })
    //     )
    //   );
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {persona === "fisica" ? (
        physicalPerson.value === undefined ? (
          <OnQuery />
        ) : (
          <ResourceRender
            resource={clients}
            Data={Dialogo}
            Query={OnQuery}
            Empty={OnEmpty}
            Error={OnError}
          />
        )
      ) : legalPerson.value === undefined ? (
        <OnQuery />
      ) : (
        <ResourceRender
          resource={clients}
          Data={Dialogo}
          Query={OnQuery}
          Empty={OnEmpty}
          Error={OnError}
        />
      )}
    </>
  );
};
