import React, { useCallback } from "react";
import FormAsesores from "../FormAsesores";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { POST_ADVISER_CODE } from "../../../constants/codes";
import { ADDAdviser, EditAdviser } from "../../../redux/actions";
import { FIX_URL } from "ghost-stories/dist/constants";
import { Resource } from "ghost-stories/dist/unions/Resource";

export const firstFile = (e) => e.target.files.item(0);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const cod = "?code=" + POST_ADVISER_CODE;
  const form = useSelector((state) => state.adviser.form);
  const history = useHistory();
  const onCancelar = () => {
    history.goBack();
  };

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditAdviser({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChageState = useCallback(
    (event) => {
      const valor = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditAdviser({ ...form, AdviserState: valor() }));
    },
    [form, dispatch]
  );

  const onChangePhoto = useCallback(
    (event) => {
      var myHeaders = new Headers();
      myHeaders.append("type", "file");

      var formdata = new FormData();
      formdata.append("image", firstFile(event));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://az-fz-dbapi.azurewebsites.net/api/picture/upload?code=VZzIKS44D78LREowqJwxIGcqMARTo0S8at76Cot/1mE65fax/6aHnQ==",
        requestOptions
      ).then((response) => response.text());
      setTimeout(
        dispatch(
          EditAdviser({
            ...form,
            AdviserPhotoURL:
              "https://fortaleza.blob.core.windows.net/pictures/" +
              event.target.files[0].name,
          })
        ),

        1000
      );
    },
    [dispatch, form]
  );

  const onSave = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(ADDAdviser(Resource.Query({ [FIX_URL]: cod, ...form })));
      setTimeout(history.goBack, 1000);
    },
    [dispatch, form, history, cod]
  );
  return (
    <FormAsesores
      {...{
        title: "Agregar Asesor/a",
        onCancelar,
        form,
        onChangeField,
        onChageState,
        onSave,
        onChangePhoto,
      }}
    />
  );
};
