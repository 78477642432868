import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, TablePagination } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover text-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-left">Contrato nro</th>
            <th className="text-left">Plan</th>
            <th className="text-left">Aportes</th>
            <th className="text-left">Monto aportado</th>
            <th className="text-left">Estado</th>
            <th className="text-left"></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="text-left">3866</td>
            <td className="text-left">Plan 5</td>
            <td className="text-left">19/240</td>
            <td className="text-left">17.907.500</td>
            <td className="text-left">
              <div
                className="badge px-4"
                style={{ color: "white", backgroundColor: "#007F79" }}
              >
                Activo
              </div>
            </td>

            <td className="text-center">
              <Tooltip arrow title="Ver más">
                <IconButton size="small" variant="outlined" color="secondary">
                  <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
                </IconButton>
              </Tooltip>
            </td>
          </tr>

          <tr>
            <td className="text-left">3571</td>
            <td className="text-left">Plan Sistema</td>
            <td className="text-left">63/240</td>
            <td className="text-left">66.265.000</td>
            <td className="text-left">
              <div
                className="badge px-4"
                style={{ color: "white", backgroundColor: "#007F79" }}
              >
                Activo
              </div>
            </td>

            <td className="text-center">
              <Tooltip arrow title="Ver más">
                <IconButton size="small" variant="outlined" color="secondary">
                  <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
                </IconButton>
              </Tooltip>
            </td>
          </tr>

          <tr>
            <td className="text-left">2221</td>
            <td className="text-left">Plan 20</td>
            <td className="text-left">15/240</td>
            <td className="text-left">13.830.000</td>
            <td className="text-left">
              <div className="badge badge-dark px-4">Baja</div>
            </td>

            <td className="text-center">
              <Tooltip arrow title="Ver más">
                <IconButton size="small" variant="outlined" color="secondary">
                  <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
                </IconButton>
              </Tooltip>
            </td>
          </tr>

          <tr>
            <td className="text-left">8659</td>
            <td className="text-left">Plan 20</td>
            <td className="text-left">29/240</td>
            <td className="text-left">32.085.000</td>
            <td className="text-left">
              <div
                className="badge px-4"
                style={{ color: "white", backgroundColor: "#007F79" }}
              >
                Activo
              </div>
            </td>

            <td className="text-center">
              <Tooltip arrow title="Ver más">
                <IconButton size="small" variant="outlined" color="secondary">
                  <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
                </IconButton>
              </Tooltip>
            </td>
          </tr>

          <tr>
            <td className="text-left">5132</td>
            <td className="text-left">Plan 20</td>
            <td className="text-left">1/240</td>
            <td className="text-left">477.500</td>
            <td className="text-left">
              <div
                className="badge px-4"
                style={{ color: "white", backgroundColor: "#007F79" }}
              >
                Activo
              </div>
            </td>

            <td className="text-center">
              <Tooltip arrow title="Ver más">
                <IconButton size="small" variant="outlined" color="secondary">
                  <FontAwesomeIcon icon={["fa", "ellipsis-v"]} />
                </IconButton>
              </Tooltip>
            </td>
          </tr>
        </tbody>
      </table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
