import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { ADD_ATC, ATC, ATC_ALL, MODIFY_ATC } from "../actionTypes";
import { captureATC, captureAllATC, updateATCs, addATCs } from "../api";
import {
  ModifyATC,
  ObtainAllATC,
  ObtainATCS,
  MostrarMensaje,
  ADDATC,
} from "../actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { ATC_CODE } from "../../constants/codes";

const addATC = (action$) =>
  action$.pipe(ofType(ADD_ATC), toParams, makeRequest(addATCs), map(ADDATC));

const onSave = (action$) =>
  action$.pipe(
    ofType(ADD_ATC),
    filter((action) => Resource.isQuery(action.payload)),
    mergeMap(() => [
      ObtainAllATC(Resource.Query({ code: ATC_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "ATC agregado con éxito",
        tipo: "success",
      }),
    ])
  );

const listATC = (action$) =>
  action$.pipe(ofType(ATC), toParams, makeRequest(captureATC), map(ObtainATCS));

const listATCAll = (action$) =>
  action$.pipe(
    ofType(ATC_ALL),
    toParams,
    makeRequest(captureAllATC),
    map(ObtainAllATC)
  );

const updateATC = (action$) =>
  action$.pipe(
    ofType(MODIFY_ATC),
    toParams,
    makeRequest(updateATCs),
    map(ModifyATC)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_ATC),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      ObtainAllATC(Resource.Query({ code: ATC_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "ATC editado con éxito",
        tipo: "success",
      }),
    ])
  );
export default combineEpics(
  listATC,
  listATCAll,
  updateATC,
  onSaveEdit,
  addATC,
  onSave
);
