import React from "react";
import { Card, Box } from "@material-ui/core";
import ResumenContratosCards from "./ResumenContratosCards";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, id, contracts }) => {
  const client = value.map((x) => x).find((xs) => xs.CustomerID === id);

  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2">
        <div className="card-header--title py-2 font-size-lg font-weight-bold">
          Resumen de contratos
        </div>
        <Box className="card-header--actions"></Box>
      </div>
      <ResourceRender
        resource={contracts}
        Data={ResumenContratosCards}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
        props={{ client: client }}
      />
    </Card>
  );
};
