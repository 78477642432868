import { merge } from "remeda";
import { CREATE_CASE } from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  agregar: Resource.Empty({}),
};

const caso = (state = initial, action) => {
  switch (action.type) {
    case CREATE_CASE:
      return merge(state, { agregar: action.payload });
    default:
      return state;
  }
};

export default caso;
