import React, { useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import TablaDeDocumentaciones from "./TablaDeDocumentaciones";
import ROUTES from "../../../constants/routes";
import ResourceRender from "ghost-stories/dist/react/resource";
import { Card, CardContent, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_LISTA_DOCUMENTACION } from "../../../constants/codes";
import { LimpiarDocumentos, ListaDeDocumentos } from "../../../redux/actions";
import { OnQuery, OnError, OnEmpty } from "../../ResourceHelpers";

const Tabla = () => {
  const dispatch = useDispatch();
  const documentos = useSelector((state) => state.documentos.lista);
  
  useEffect(() => {
    dispatch(
      ListaDeDocumentos(documentos.update({ code: CODIGO_LISTA_DOCUMENTACION }))
    );
    dispatch(LimpiarDocumentos({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <h6 className="font-size-lg mt-2 text-dark">
              Gestión de documentos
            </h6>
          </div>
          <div className="card-header--actions">
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              component={Link}
              to={ROUTES.AGREGAR_DOCUMENTACION}
            >
              Agregar
            </Button>
          </div>
        </div>
        <CardContent className="p-3">
          <ResourceRender
            resource={documentos}
            Data={TablaDeDocumentaciones}
            Query={OnQuery}
            Empty={OnError}
            Error={OnEmpty}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Tabla;
