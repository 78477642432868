import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { EditPhysicalPerson } from "../../../../../../redux/actions";

const Informacionlaboral = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  // const [antiguedad, setAntiguedad] = useState(form.ClientAntiquity);
  // const [tipoIngreso, setTipoIngreso] = useState(form.ClientIncomeType);
  // const [promedioIngresos, setPromedioIngresos] = useState(
  //   form.ClientAverageIncome
  // );
  // const [otrasInversiones, setOtrasInversiones] = useState(
  //   form.ClientOtherInvestments
  // );
  // const [otraInversionSi, setOtraInversionSi] = useState(
  //   form.ClientOtherActiveInvestments
  // );
  // const [fortalezaInfo, setFortalezaInfo] = useState(form.ClientHowMeet);
  const [declaJurada, setDeclaJurada] = useState(form.ClientSwornDeclaration);
  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  // const onChangeAntiguedad = (i) => (e) => {
  //   let newArr = [...antiguedad];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setAntiguedad(newArr);
  // };

  // const onChangeTipoIngreso = (i) => (e) => {
  //   let newArr = [...tipoIngreso];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setTipoIngreso(newArr);
  // };

  // const onChangeAverageIncome = (i) => (e) => {
  //   let newArr = [...promedioIngresos];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setPromedioIngresos(newArr);
  // };

  // const onChangeOtherInvestments = (i) => (e) => {
  //   let newArr = [...otrasInversiones];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setOtrasInversiones(newArr);
  // };

  // const onChangeOtherActiveInvestments = (i) => (e) => {
  //   let newArr = [...otraInversionSi];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setOtraInversionSi(newArr);
  // };

  // const onChangeClientHowMeet = (i) => (e) => {
  //   let newArr = [...fortalezaInfo];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setFortalezaInfo(newArr);
  // };

  const onChangeClientSwornDeclaration = (i) => (e) => {
    let newArr = [...declaJurada];
    newArr[i] = {
      swornDeclarationTypeId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setDeclaJurada(newArr);
  };

  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        // ClientAntiquity: antiguedad,
        // ClientIncomeType: tipoIngreso,
        // ClientAverageIncome: promedioIngresos,
        // ClientOtherInvestments: otrasInversiones,
        // ClientOtherActiveInvestments: otraInversionSi,
        // ClientHowMeet: fortalezaInfo,
        ClientSwornDeclaration: declaJurada,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // antiguedad,
    // tipoIngreso,
    // promedioIngresos,
    // otrasInversiones,
    // otraInversionSi,
    // fortalezaInfo,
    declaJurada,
  ]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Lugar de trabajo"
            variant="outlined"
            value={form.ClientWorkplace}
            name="ClientWorkplace"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Teléfono"
            variant="outlined"
            value={form.ClientWorkPhone}
            name="ClientWorkPhone"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Dirección"
            variant="outlined"
            value={form.ClientWorkAddress}
            name="ClientWorkAddress"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Cargo"
            variant="outlined"
            value={form.ClientJobTitle}
            name="ClientJobTitle"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        {/* <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={3}>
          <div className="font-size-lg ">Antigüedad</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {antiguedad.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeAntiguedad(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={7}>
          <div className="font-size-lg ">Tipo de Ingreso</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {tipoIngreso.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeTipoIngreso(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={7}>
          <div className="font-size-lg ">Promedio de ingreso</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {promedioIngresos.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeAverageIncome(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className="font-size-lg ">Otras inversiones</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {otrasInversiones.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeOtherInvestments(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={1}></Grid>

        {otrasInversiones[0].state === true ? (
          <>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <div className="font-size-lg ">
                Seleccione la/s otra/as inversión/es con la que cuenta
              </div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="pr-4">
                <FormGroup row>
                  {otraInversionSi.map((x, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChangeOtherActiveInvestments(i)}
                          checked={x.state}
                          value={x.title}
                        />
                      }
                      label={x.title}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <div className="font-size-lg ">¿Cómo conoció Fortaleza?</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {fortalezaInfo.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeClientHowMeet(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1}></Grid> */}

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <strong>DECLARACIÓN JURADA DE ORIGEN DE INSTRUMENTO DE PAGO</strong>
          <p>
            Declaro que los fondos a ser utilizados para realizar los pagos de
            los aportes/adelantos, provendrán de una fuente licita y no tiene
            relación alguna con dinero, capitales, bienes, haberes, valores o
            títulos, producto de las actividades a las que se refiere la Ley No
            1015/97 y sus modificatorias, la Ley 3783/09 y la Ley 6497/2019 “Que
            previene y reprime los actos ilícitos destinados a la legitimación
            de dinero o bienes”, en concordancia con la Resolución 201/2020 de
            la SEPRELAD y demás leyes vigentes y concordantes en la materia.
            Realizaré los pagos con ingresos provenientes de:
          </p>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {declaJurada.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeClientSwornDeclaration(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        {declaJurada[declaJurada.length - 2].state === true ? (
          <>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                className="mb-3"
                label="Nombre"
                variant="outlined"
                value={form.ClientSwornDeclarationName}
                name="ClientSwornDeclarationName"
                onChange={onChangeField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>

            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                className="mb-3"
                label="CI"
                variant="outlined"
                value={form.ClientSwornDeclarationCI}
                name="ClientSwornDeclarationCI"
                onChange={onChangeField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>

            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                className="mb-3"
                label="Relación/parentesco"
                variant="outlined"
                value={form.ClientSwornDeclarationRelation}
                name="ClientSwornDeclarationRelation"
                onChange={onChangeField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
          </>
        ) : null}

        {declaJurada[declaJurada.length - 1].state === true ? (
          <>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                className="mb-3"
                label="Especificar"
                variant="outlined"
                value={form.ClientSwornDeclarationOther}
                name="ClientSwornDeclarationOther"
                onChange={onChangeField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
          </>
        ) : null}
      </Grid>
    </form>
  );
};

export default Informacionlaboral;
