import React, { useEffect } from "react";
import {
  FormControlLabel,
  Checkbox,
  Card,
  FormControl,
  Divider,
  FormGroup,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { EditarCliente } from "../../../../redux/actions";
import { UrlPinDialog } from "./UrlPinDialog";

const DocumentosSolicitados = (props) => {
  const { value, form, onGuardar } = props;
  const dispatch = useDispatch();
  const DocSolicitados = value.data.filter((x) => x.DocumentState === "S");
  const documentos = DocSolicitados.map((x) => x.DocumentName);
  const y = documentos.map((a) => ({
    titulo: a,
    estado: false,
    sent: false,
    fileURL: "",
  }));
  const [state, setState] = React.useState(y);

  useEffect(() => {
    dispatch(
      EditarCliente({
        ...form,
        ClientRequestedDocuments: JSON.stringify(state),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...state];
    newArr[index] = {
      titulo: e.target.value,
      estado: e.target.checked,
      sent: false,
      fileURL: "",
    };

    setState(newArr);
  };

  return (
    <>
      <Card className="p-4 mb-4">
        <div className="font-size-lg ">Documentos a Solicitar</div>
        <Divider className="my-4" />
        <FormControl component="fieldset" className="pr-4">
          <FormGroup>
            {DocSolicitados.map((x, i) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={updateFieldChanged(i)}
                    value={x.DocumentName}
                  />
                }
                label={x.DocumentName}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Card>
      <div className="card-footer p-3 text-right">
        <UrlPinDialog
          form={form}
          states={state}
          onGuardar={onGuardar}
          cedula={form.ClientCI}
        />
      </div>
    </>
  );
};

export default DocumentosSolicitados;
