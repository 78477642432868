import React, { useRef } from "react";
import { Grid, Card, CardContent, List, ListItem } from "@material-ui/core";
import TablaDetalle from "./components/TablaDetalle";
// import ExportExcel from "react-export-excel";
import { PDFExport } from "@progress/kendo-react-pdf";

// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelSheet;
// const ExcelColumn = ExportExcel.ExcelColumn;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value }) => {
  const contributions = value[0].Fullfeesdata;
  const pdfExportComponent = useRef(null);

  // const exportPDF = (event) => {
  //   pdfExportComponent.current.save();
  // };
  
  return (
    <Grid container spacing={4} className="mt-3">
      <Grid item xs={12} lg={12}>
        <Card className="card-box">
          <List tag="div">
            <ListItem tag="div" className="p-0 d-block">
              <CardContent className="p-3">
                <div>
                  {value.map((x) => (
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={5}>
                        <div className="text-center">
                          <span className="text-black-50 d-block">
                            Fecha de ingreso
                          </span>
                          <b className="font-size-xxl text-primary">
                            {x.BeginContractDate}
                          </b>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <div className="text-center">
                          <span className="text-black-50 d-block">
                            Fecha de fin de contrato
                          </span>
                          <b
                            className="font-size-xxl"
                            style={{ color: "#007F79" }}
                          >
                            {x.EndContractDate}
                          </b>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        {/* <div className="text-center d-flex align-items-center justify-content-center mb-3">
                          <ExcelFile
                            element={
                              <Tooltip arrow title="Descargar a excel">
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  className="m-1 d-inline-flex align-items-center justify-content-center text-center font-size-xxl d-50 rounded"
                                >
                                  <FontAwesomeIcon
                                    icon={["far", "file-excel"]}
                                    className="font-size-lg"
                                  />
                                </Button>
                              </Tooltip>
                            }
                            filename="Aportes"
                          >
                            <ExcelSheet data={contributions} name="Aportes">
                              <ExcelColumn
                                label="Concepto"
                                value="FeeConcept"
                              />
                              <ExcelColumn label="Tipo" value="FeeType" />
                              <ExcelColumn label="Fecha" value="FeeDueDate" />
                              <ExcelColumn label="Estado" value="FeeStatus" />
                              <ExcelColumn
                                label="Monto"
                                value="FeeGrossAmount"
                              />
                              <ExcelColumn
                                label="Descuento"
                                value="FeeDiscountAmount"
                              />
                              <ExcelColumn
                                label="Multa"
                                value="FeePenaltyAmount"
                              />
                              <ExcelColumn label="Pagado" value="FeeAmount" />
                            </ExcelSheet>
                          </ExcelFile>

                          <Tooltip arrow title="Descargar a pdf">
                            <Button
                              color="primary"
                              variant="outlined"
                              className="m-1 d-inline-flex align-items-center justify-content-center text-center font-size-xxl d-50 rounded"
                              onClick={exportPDF}
                            >
                              <FontAwesomeIcon
                                icon={["far", "file-pdf"]}
                                className="font-size-lg"
                              />
                            </Button>
                          </Tooltip>
                        </div> */}
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <div>
                  <PDFExport
                    ref={pdfExportComponent}
                    paperSize="A4"
                    fileName="Aportes"
                  >
                    <TablaDetalle contributions={contributions} />
                  </PDFExport>
                </div>
              </CardContent>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};
