import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  CleanAdviser,
  EditAdviser,
  ModifyAdviser,
} from "../../../redux/actions";
import FormAsesores from "../FormAsesores";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";
import { PUT_ADVISER_CODE } from "../../../constants/codes";

export const firstFile = (e) => e.target.files.item(0);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const cod = "?code=" + PUT_ADVISER_CODE;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.adviser.form);

  const original = useSelector((state) =>
    state.adviser.all.value.data.map((x) => x).find((xs) => xs.AdviserID === id)
  );

  useEffect(() => {
    if (original === undefined) {
      return history.goBack();
    }
    dispatch(EditAdviser(original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original]);
  const onCancelar = useCallback(() => {
    dispatch(CleanAdviser({}));
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditAdviser({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  const onChageState = useCallback(
    (event) => {
      const valor = () => {
        if (event.target.checked === true) {
          return "S";
        }
        return "N";
      };
      dispatch(EditAdviser({ ...form, AdviserState: valor() }));
    },
    [form, dispatch]
  );

  const onChangePhoto = useCallback(
    (event) => {
      var myHeaders = new Headers();
      myHeaders.append("type", "file");

      var formdata = new FormData();
      formdata.append("image", firstFile(event));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://az-fz-dbapi.azurewebsites.net/api/picture/upload?code=VZzIKS44D78LREowqJwxIGcqMARTo0S8at76Cot/1mE65fax/6aHnQ==",
        requestOptions
      ).then((response) => response.text());
      setTimeout(
        dispatch(
          EditAdviser({
            ...form,
            AdviserPhotoURL:
              "https://fortaleza.blob.core.windows.net/pictures/" +
              event.target.files[0].name,
          })
        ),
        1000
      );
    },
    [dispatch, form]
  );

  const onSave = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(ModifyAdviser(Resource.Query({ [FIX_URL]: id + cod, ...form })));
      history.goBack();
    },
    [dispatch, history, id, form, cod]
  );
  return (
    <FormAsesores
      {...{
        title: "Editar Asesor/a",
        form,
        onCancelar,
        onChangeField,
        onChageState,
        onSave,
        onChangePhoto,
      }}
    />
  );
};
