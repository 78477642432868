import React from "react";
import Tabla from "./Tabla";

const Documentacion = () => {
  return (
    <>
      <Tabla />
    </>
  );
};

export default Documentacion;
