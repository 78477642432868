import React, { useEffect } from "react";
import Notifications from "./Notifications";
import {
  OnQuery,
  OnError,
  OnEmptyNotification,
} from "../../../../pages/ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import { ObtainNotifications } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import authentication from "@kdpw/msal-b2c-react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const id_token = authentication.getIdToken();
  const Role = id_token.idp;
  const ro = () => {
    if (Role !== undefined) {
      return "ADMINISTRATOR";
    } else {
      return "USER";
    }
  };
  const valid = ro() === "ADMINISTRATOR";
  const id = id_token.extension_CI;
  const notifications = useSelector((state) => state.notifications.list);

  useEffect(() => {
    dispatch(ObtainNotifications(notifications.update({ nrodocumento: id })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {!valid ? (
        <ResourceRender
          resource={notifications}
          Data={Notifications}
          Query={OnQuery}
          Empty={OnEmptyNotification}
          Error={OnError}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};
