import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { ADD_BUILD, BUILDINGS, MODIFY_BUILD } from "../actionTypes";
import { addBuildings, captureBuildings, updateBuildings } from "../api";
import {
  ModifyBuild,
  ObtainBuildings,
  MostrarMensaje,
  ADDBuild,
} from "../actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { BUILD_CODE } from "../../constants/codes";

const addBuild = (action$) =>
  action$.pipe(
    ofType(ADD_BUILD),
    toParams,
    makeRequest(addBuildings),
    map(ADDBuild)
  );

const onSave = (action$) =>
  action$.pipe(
    ofType(ADD_BUILD),
    filter((action) => Resource.isQuery(action.payload)),
    mergeMap(() => [
      ObtainBuildings(Resource.Query({ code: BUILD_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "Edificio agregado con éxito",
        tipo: "success",
      }),
    ])
  );

const listBuildings = (action$, state$) =>
  action$.pipe(
    ofType(BUILDINGS),
    toParams,
    makeRequest(captureBuildings),
    map(ObtainBuildings)
  );

const updateBuild = (action$) =>
  action$.pipe(
    ofType(MODIFY_BUILD),
    toParams,
    makeRequest(updateBuildings),
    map(ModifyBuild)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFY_BUILD),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      ObtainBuildings(Resource.Query({ code: BUILD_CODE })),
      MostrarMensaje({
        open: true,
        mensaje: "Edificio editado con éxito",
        tipo: "success",
      }),
    ])
  );

export default combineEpics(
  listBuildings,
  updateBuild,
  onSaveEdit,
  addBuild,
  onSave
);
