import React, { useEffect } from "react";
import DocumentosSolicitados from "./Agregar/Components/DocumentosSolicitados";
import ResourceRender from "ghost-stories/dist/react/resource";
import { Grid, Card, TextField, CardContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { OnQuery, OnError, OnEmpty } from "../../pages/ResourceHelpers";
import { ListaDeDocumentos } from "../../redux/actions";
import { CODIGO_LISTA_DOCUMENTACION } from "../../constants/codes";

const FormCliente = (props) => {
  const dispatch = useDispatch();
  const { title, form, onChangeField, onGuardar } = props;
  const documentos = useSelector((state) => state.documentos.lista);
  useEffect(() => {
    dispatch(
      ListaDeDocumentos(documentos.update({ code: CODIGO_LISTA_DOCUMENTACION }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <h6 className="font-size-lg mt-2 text-dark">{title}</h6>
          </div>
        </div>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                className="mb-3"
                label="Nombre"
                name="ClientName"
                onChange={onChangeField}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                className="mb-3"
                label="Apellido"
                name="ClientLastName"
                onChange={onChangeField}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                className="mb-3"
                label="CI"
                name="ClientCI"
                onChange={onChangeField}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <ResourceRender
                resource={documentos}
                Data={DocumentosSolicitados}
                Query={OnQuery}
                Empty={OnError}
                Error={OnEmpty}
                props={{ form }}
                matchingProps={{
                  Data: { onGuardar },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default FormCliente;
