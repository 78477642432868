import { merge } from "remeda";
import { ATC, ATC_ALL, EDIT_ATC, CLEAN_ATC, ADD_ATC } from "../actionTypes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const initial = {
  list: Resource.Empty({ AtcID: "" }),
  all: Resource.Empty({ code: "" }),
  form: {
    AtcID: "",
    ATCName: "",
    ATCLastname: "",
    ATCNumber: "",
    ATCMail: "",
    ATCPhotoURL: "https://i.postimg.cc/gJ0N0ry5/avatar.png",
    ATCState: "S",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case ATC:
      return merge(state, { list: action.payload });
    case ATC_ALL:
      return merge(state, { all: action.payload });
    case ADD_ATC:
      return merge(state, { form: action.payload });
    case EDIT_ATC:
      return merge(state, { form: action.payload });
    case CLEAN_ATC:
      return merge(state, { form: initial.form });
    default:
      return state;
  }
};
