import { merge } from "remeda";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  ADD_PHYSICAL_PERSON,
  EDIT_PHYSICAL_PERSON,
  PHYSICAL_PERSON,
} from "../actionTypes";

const initial = {
  list: Resource.Empty({ ClientID: "" }),
  form: {
    ClientID: "",
    ClientName: "",
    ClientLastName: "",
    ClientPhoneNumber: "",
    ClientBirtDate: new Date(),
    ClientWorkArea: "",
    ClientJobRole: "",
    ClientJobOccupation: "",
    ClientAnotherOccupation: "",
    ClientMainActivities: "",
    ClientAnotherActivities: "",
    ClientPreferredContactMethod: "",
    ClientDocumentType: "",
    ClientDocumentNumber: "",
    ClientDocumentExpirationDate: new Date(),
    ClientHasRuc: "",
    ClientRucNumber: "",
    ClientReferredBy: "",
    ClientReferenceContractNumber: "",
    ClientNationality: "",
    ClientProfession: "",
    ClientHomeAddress: "",
    ClientHouseNumber: "",
    ClientFloor: "",
    ClientCity: "",
    ClientNeighborhood: "",
    ClientCouplesName: "",
    ClientAnniversary: new Date(),
    ClientChildrens: "",
    ClientStudying: "",
    ClientSex: "",
    ClientCivilStatus: "",
    ClientPayingCredit: "",
    ClientHousingSituation: "",
    ClientStudies: "",
    ClientWorkplace: "",
    ClientWorkPhone: "",
    ClientWorkAddress: "",
    ClientJobTitle: "",
    ClientAntiquity: "",
    ClientIncomeType: "",
    ClientAverageIncome: "",
    ClientOtherInvestments: "",
    ClientOtherActiveInvestments: "",
    ClientHowMeet: "",
    ClientSwornDeclaration: "",
    ClientSwornDeclarationName: "",
    ClientSwornDeclarationCI: "",
    ClientSwornDeclarationRelation: "",
    ClientSwornDeclarationOther: "",
    ClientTypeEntryToSystem: "",
    ClientReasonEnteringToSystem: "",
    ClientInvestmentProfile: "",
    ClientInscription: "",
    ClientFirstContribution: "",
    ClientSecondContribution: "",
    ClientMonthlyPaymentDate: new Date(),
    ClientObservations: "",
    ClientFormPaymentContributions: "",
    ClientPaymentDate: "",
    ClientSendNotifications: "",
    ClientLocation: "",
    ClientPublicOffice: "",
    ClientEntityName: "",
    ClientHeldPosition: "",
    ClientStartInOffice: new Date(),
    ClientEndInOffice: new Date(),
    ClientIsSpouse: "",
    ClientPublicPositionPartner: "",
    ClientLegalPersonsOrEntities: "",
    ClientShareholderPartner: "",
    ClientShareholderPartnerTable: "",
    ClientSeniorManagementMember: "",
    ClientSeniorManagementMemberTable: "",
    ClientStep1: "",
    ClientStep2: "",
    ClientStep3: "",
    ClientStep4: "",
    ClientStep5: "",
    ClientStep6: "",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initial, action) => {
  switch (action.type) {
    case PHYSICAL_PERSON:
      return merge(state, { list: action.payload });
    case ADD_PHYSICAL_PERSON:
      return merge(state, { form: action.payload });
    case EDIT_PHYSICAL_PERSON:
      return merge(state, { form: action.payload });
    default:
      return state;
  }
};
