import React from "react";
import { Alert } from "@material-ui/lab";
import OnEmptyNotifications from "./components/OnEmptyNotifications";

export const OnQuery = () => <Alert severity="info">Cargando...</Alert>;
export const OnEmpty = () => (
  <Alert severity="warning">No se obtuvieron resultados</Alert>
);
export const OnError = () => (
  <Alert severity="error">Hubo un error al cargar tus datos</Alert>
);

export const OnEmptyNotification = () => <OnEmptyNotifications />;
