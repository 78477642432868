import React from "react";
import { Card } from "@material-ui/core";
import Tabla from "./Tabla";
import HeaderInversiones from "../components/HeaderInversiones";
import Footer from "../components/Footer";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div>
      <HeaderInversiones />
      <Card className="card-box p-4 mb-4">
        <Tabla />
      </Card>
      <Footer />
    </div>
  );
};
