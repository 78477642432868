import React from "react";
import {
  Grid,
  Card,
  TextField,
  CardContent,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const {
    title,
    form,
    onCancelar,
    onChangeField,
    onChageVisibility,
    onSave,
    onChangeDate,
  } = props;
  const valor = () => {
    if (form.BuildVisibility === "S") {
      return true;
    }
    return false;
  };
  return (
    <form onSubmit={onSave}>
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <h6 className="font-size-lg mt-2 text-dark">{title}</h6>
          </div>
        </div>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                className="mb-3"
                label="ID"
                variant="outlined"
                value={form.BuildID}
                onChange={onChangeField}
                name="BuildID"
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Nombre del edificio"
                variant="outlined"
                value={form.BuildName}
                onChange={onChangeField}
                name="BuildName"
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Alias del edificio"
                variant="outlined"
                value={form.BuildAlias}
                onChange={onChangeField}
                name="BuildAlias"
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="Año de Inauguración"
                variant="outlined"
                value={form.BuildDate}
                onChange={onChangeDate}
                required
              />
              <TextField
                fullWidth
                className="mb-3"
                label="URL"
                variant="outlined"
                value={form.BuildURL}
                onChange={onChangeField}
                name="BuildURL"
                required
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <FormControlLabel
                control={
                  <Switch
                    name="activo"
                    checked={valor()}
                    onChange={onChageVisibility}
                  />
                }
                label="Activo"
                className="mb-3"
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </CardContent>
        <div className="card-footer p-3 text-right">
          <Button
            className="mr-3"
            size="small"
            color="primary"
            variant="outlined"
            onClick={onCancelar}
          >
            <span className="btn-wrapper--label">Cancelar</span>
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
          >
            <span className="btn-wrapper--label">Guardar</span>
          </Button>
        </div>
      </Card>
    </form>
  );
};
