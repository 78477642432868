import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CiudadesConBarrios from "../../../../../../constants/CiudadesConBarrios.json";
import { useDispatch } from "react-redux";
import { EditPhysicalPerson } from "../../../../../../redux/actions";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { estadoCivilInit, sexoInit } from "../../../../../../constants/helpers";

const DetallePersonaFisica = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const ciudades = CiudadesConBarrios.map((x) => x.Ciudad);
  const ciudadesFiltered = ciudades.filter((x, y) => ciudades.indexOf(x) === y);
  const barrios = CiudadesConBarrios.filter(
    (x) => x.Ciudad === form.ClientCity
  );
  const barriosFiltered = barrios.filter((x, y) => barrios.indexOf(x) === y);
  // const [estudiando, setEstudiando] = useState(form.ClientStudying);
  const [sexo, setSexo] = useState(form.ClientSex);
  const [estadoCivil, setEstadoCivil] = useState(form.ClientCivilStatus);
  // const [pagandoCasa, setPagandoCasa] = useState(form.ClientPayingCredit);
  // const [situacionDeVivienda, setSituacionDeVivienda] = useState(
  //   form.ClientHousingSituation
  // );
  // const [estudios, setEstudios] = useState(form.ClientStudies);
  const [clientCitys, setClientCitys] = useState(form.ClientCity);
  const [clientBarrios, setClientBarrios] = useState(form.ClientNeighborhood);

  const onChangeCiudad = (e, ciudad) => {
    setClientCitys(ciudad.toString());
  };

  const onChangeBarrio = (e, barrio) => {
    setClientBarrios(barrio.toString());
  };

  // const onChangeStudying = (i) => (e) => {
  //   let newArr = [...estudiando];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setEstudiando(newArr);
  // };

  const onChangeSex = (i) => (e) => {
    let newArr = [...sexoInit];
    newArr[i] = {
      genderId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setSexo(newArr);
  };

  const onChangeCivilStatus = (i) => (e) => {
    let newArr = [...estadoCivilInit];
    newArr[i] = {
      civilStatusId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setEstadoCivil(newArr);
  };

  // const onChangePayingCredit = (i) => (e) => {
  //   let newArr = [...pagandoCasa];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setPagandoCasa(newArr);
  // };

  // const onChangeHousingSituation = (i) => (e) => {
  //   let newArr = [...situacionDeVivienda];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setSituacionDeVivienda(newArr);
  // };

  // const onChangeStudies = (i) => (e) => {
  //   let newArr = [...estudios];
  //   newArr[i] = {
  //     title: e.target.value,
  //     state: e.target.checked,
  //   };

  //   setEstudios(newArr);
  // };

  const handleAniversaryChange = useCallback(
    (date) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          ClientAnniversary: date.toString(),
        })
      );
    },
    [form, dispatch]
  );

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        ClientCity: clientCitys,
        ClientNeighborhood: clientBarrios,
        // ClientStudying: estudiando,
        ClientSex: sexo,
        ClientCivilStatus: estadoCivil,
        // ClientPayingCredit: pagandoCasa,
        // ClientHousingSituation: situacionDeVivienda,
        // ClientStudies: estudios,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientCitys,
    clientBarrios,
    // estudiando,
    sexo,
    estadoCivil,
    // pagandoCasa,
    // situacionDeVivienda,
    // estudios,
  ]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Nacionalidad"
            variant="outlined"
            value={form.ClientNationality}
            name="ClientNationality"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Profesión"
            variant="outlined"
            value={form.ClientProfession}
            name="ClientProfession"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Dirección Particular"
            variant="outlined"
            value={form.ClientHomeAddress}
            name="ClientHomeAddress"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            className="mb-3"
            label="Nro"
            variant="outlined"
            value={form.ClientHouseNumber}
            name="ClientHouseNumber"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            className="mb-3"
            label="Piso"
            variant="outlined"
            value={form.ClientFloor}
            name="ClientFloor"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            disableClearable
            options={ciudadesFiltered}
            getOptionLabel={(option) => option}
            onChange={onChangeCiudad}
            value={form.ClientCity}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ciudad"
                variant="outlined"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            disableClearable
            options={barriosFiltered.map((x) => x.Barrio)}
            getOptionLabel={(option) => option}
            onChange={onChangeBarrio}
            value={form.ClientNeighborhood}
            noOptionsText="Seleccione la ciudad"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Barrio"
                variant="outlined"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Nombre del cónyuge o pareja"
            variant="outlined"
            value={form.ClientCouplesName}
            name="ClientCouplesName"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="Aniversario"
              value={form.ClientAnniversary}
              onChange={(date) => handleAniversaryChange(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            className="mb-3"
            label="Hijos (Cantidad)"
            variant="outlined"
            value={form.ClientChildrens}
            name="ClientChildrens"
            onChange={onChangeField}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          {/* <div className="font-size-lg ">Estudiando</div>
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {estudiando.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeStudying(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl> */}
        </Grid>
        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={3}>
          <div className="font-size-lg ">Sexo</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {sexo.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeSex(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={7}>
          <div className="font-size-lg ">Estado civil</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {estadoCivil.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeCivilStatus(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={1}></Grid>

        {/* <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={3}>
          <div className="font-size-lg ">Pagando crédito de casa propia</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {pagandoCasa.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangePayingCredit(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <div className="font-size-lg ">Situación de vivienda</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {situacionDeVivienda.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeHousingSituation(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className="font-size-lg ">Estudios</div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="pr-4">
            <FormGroup row>
              {estudios.map((x, i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChangeStudies(i)}
                      checked={x.state}
                      value={x.title}
                    />
                  }
                  label={x.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={1}></Grid> */}
      </Grid>
    </form>
  );
};

export default DetallePersonaFisica;
