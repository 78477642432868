import React, { useEffect } from "react";
import Footer from "../components/Footer";
import HeaderVendedor from "../components/HeaderVendedor";
import EdificiosFortaleza from "./components/EdificiosFortaleza";
import { useDispatch, useSelector } from "react-redux";
import { ObtainBuildings } from "../../redux/actions";
import { OnQuery, OnError, OnEmpty } from "../ResourceHelpers";
import ResourceRender from "ghost-stories/dist/react/resource";
import { BUILD_CODE } from "../../constants/codes";
import { LoadingGeneral } from "../../utils/LoadingGeneral";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const buildings = useSelector((state) => state.buildings.list);
  useEffect(() => {
    dispatch(ObtainBuildings(buildings.update({ code: BUILD_CODE })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (buildings === undefined || "") {
    return <LoadingGeneral />;
  }

  return (
    <div>
      <HeaderVendedor />
      <ResourceRender
        resource={buildings}
        Data={EdificiosFortaleza}
        Query={OnQuery}
        Empty={OnEmpty}
        Error={OnError}
      />
      <Footer />
    </div>
  );
};
