import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { EditPhysicalPerson } from "../../../../../../redux/actions";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { rucInit, tipoDocInit } from "../../../../../../constants/helpers";

const Identificacion = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const [document, setDocument] = useState(form.ClientDocumentType);
  const [ruc, setRuc] = useState(form.ClientHasRuc);

  const onChangeBoxDocumentType = (i) => (e) => {
    let newArr = [...tipoDocInit];
    newArr[i] = {
      documentTypeId: i + 1,
      title: e.target.value,
      state: e.target.checked,
    };

    setDocument(newArr);
  };

  const onChangeRuc = (i) => (e) => {
    let newArr = [...rucInit];
    newArr[i] = {
      title: e.target.value,
      state: e.target.checked,
    };

    setRuc(newArr);
  };

  const handleExpiredChange = useCallback(
    (date) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          ClientDocumentExpirationDate: date.toString(),
        })
      );
    },
    [form, dispatch]
  );

  const onChangeField = useCallback(
    (event) => {
      dispatch(
        EditPhysicalPerson({
          ...form,
          [event.target.name]: event.target.value,
        })
      );
    },
    [form, dispatch]
  );

  useEffect(() => {
    dispatch(
      EditPhysicalPerson({
        ...form,
        ClientDocumentType: document,
        ClientHasRuc: ruc,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, ruc]);

  return (
    <>
      <form className="mt-3">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <div className="font-size-lg ">Tipo de Documento</div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {document.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeBoxDocumentType(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Número de documento"
              variant="outlined"
              value={form.ClientDocumentNumber}
              name="ClientDocumentNumber"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label="Vencimiento"
                value={form.ClientDocumentExpirationDate}
                onChange={(date) => handleExpiredChange(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <div className="font-size-lg ">Ruc</div>
            <Divider className="my-4" />
            <FormControl component="fieldset" className="pr-4">
              <FormGroup row>
                {ruc.map((x, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChangeRuc(i)}
                        checked={x.state}
                        value={x.title}
                      />
                    }
                    label={x.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          {ruc[ruc.length - 2].state === true ? (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  className="mb-3"
                  label="Número de RUC"
                  variant="outlined"
                  value={form.ClientRucNumber}
                  name="ClientRucNumber"
                  onChange={onChangeField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          ) : null}

          {/* <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Referido por"
              variant="outlined"
              value={form.ClientReferredBy}
              name="ClientReferredBy"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              className="mb-3"
              label="Nro. del contrato del referente"
              variant="outlined"
              value={form.ClientReferenceContractNumber}
              name="ClientReferenceContractNumber"
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid> */}
        </Grid>
      </form>
    </>
  );
};

export default Identificacion;
